import axiosInstance from "../../AxiosInstance";
import { URLS } from "../API endpoints";
import i18next from "i18next";

export const getUOM = async () => {
  try {
    const payload = {
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
    };
    const response = await axiosInstance.post(
      process.env.REACT_APP_API_URL_OLD + URLS.GET_UOM,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};



export const InventoryProductRequest = async (
  user_id,
  values,
  costPrice,
  salePrice
) => {
  console.log("API VALUEEESSS", values);

  try {
    // user_id:login.user?.userDetails?.user_id,
    const payload = {
      user_id: user_id,
      // store_id: store_id,

      product_name: values?.name,
      barcode: values?.barcode,
      sale_price: Number(salePrice),
      cost_price: Number(costPrice),
      onhand_qty: Number(values?.quantity),
      uom_id: values?.product_uom,
      machine_barcode: values?.product_code,
      // image_url: values?.image_url,
      gs_data: values?.is_gs1,
    };
    const response = await axiosInstance.post(
      process.env.REACT_APP_API_URL_OLD + URLS.PRODUCT_REQUEST,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const uploadImage = async (productId, base64Image, index) => {
  const extension = base64Image.split(";")[0].split("/")[1];
  // const filename = `${productId}.${extension}`;
  const filename = `${productId}-${index + 1}.${extension}`;
  const base64Data = base64Image.split(",")[1];
  // console.log("BASE CHALA", base64Data);

  const payload = {
    filename: filename,
    bucket: process.env.REACT_APP__IMAGE_BUCKET,
    data: base64Data,
  };

  try {
    const response = await axiosInstance.post(
      process.env.REACT_APP__IMAGE_UPLOAD,
      payload,
      {}
    );

    if (
      response.data &&
      response.data.result &&
      response.data.result.status_code === 200
    ) {
      return response.data.result.url;
    } else {
      console.error("Upload failed:", response.data);
      return null;
    }
  } catch (error) {
    console.error("Error uploading image:", error);
    return null;
  }
};

export const uploadImageUrl = async (
  productId,
  primaryImageUrl,
  secondaryImageUrls = []
) => {
  const payload = {
    product_id: productId,
    primary_image_url: primaryImageUrl,
    secondary_image_urls: secondaryImageUrls, // Pass secondary image URLs
  };

  try {
    const response = await axiosInstance.post(
      process.env.REACT_APP_API_URL_OLD + URLS.PROD_UPLOAD_IMAGE_URLS,
      payload
    );

    console.log("uploadImageUrl response:", response.data);

    // Check the response structure
    if (
      response.data &&
      response.data.result &&
      response.data.result[0].status_code === 200
    ) {
      console.log("Image URL uploaded successfully:", response.data.result[0]);
      return true; // Indicate success
    } else {
      console.error("Image URL upload failed:", response.data);
      return false; // Indicate failure
    }
  } catch (error) {
    console.error("Error uploading image URL:", error);
    return false; // Indicate failure
  }
};

export const updateGsProductRequest = async (userId, productData) => {
  const payload = {
    user_id: userId,
    product_data: productData,
  };

  const headers = {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
  };

  try {
    const response = await axiosInstance.post(
      URLS.UPDATE_GS_PRODUCT_REQUEST,
      payload,
      { headers }
    );

    console.log("updateGsProductRequest response:", response.data);

    if (response.data && response.data.status_code === 200) {
      console.log("GS1 Product request updated successfully:", response.data);
      return true; // Indicate success
    } else {
      console.error("Failed to update GS1 Product request:", response.data);
      return false; // Indicate failure
    }
  } catch (error) {
    console.error("Error updating GS1 Product request:", error);
    return false; // Indicate failure
  }
};

export const InventorySearchonboard = async (name) => {
  try {
    // console.log("Nme", name);

    // if (isInteger(name)) {
    //   barcode = name;
    //   name = "";
    // }
    // user_id:login.user?.userDetails?.user_id,
    const payload = {
      // user_id: user_id,
      // store_id: store_id,
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      product_name: name,
      // barcode: barcode,
      limit: 10,
      offset: 0,

      // query: value ? value : "",
      // limit: limit,
      // offset: offset,
    };
    const response = await axiosInstance.post(
      process.env.REACT_APP_API_URL_OLD + URLS.SEARCH_INVENTORY_ONBOARD,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const InventorySearchonboardBarcode = async (name) => {
  try {
    // console.log("Nme", name);

    // if (isInteger(name)) {
    //   barcode = name;
    //   name = "";
    // }
    // user_id:login.user?.userDetails?.user_id,
    const payload = {
      // user_id: user_id,
      // store_id: store_id,
      lang: i18next.language === "ar" ? "ar_001" : "en_US",
      barcode: name,
      // barcode: barcode,
      limit: 10,
      offset: 0,

      // query: value ? value : "",
      // limit: limit,
      // offset: offset,
    };
    const response = await axiosInstance.post(
      process.env.REACT_APP_API_URL_OLD + URLS.SEARCH_INVENTORY_ONBOARD,
      payload
    );
    return response.data;
  } catch (error) {
    return error;
  }
};

export const AddBarcodeApi = async (payload) => {
  try {
    const response = await axiosInstance.post(
      process.env.REACT_APP_API_URL_OLD + URLS.ADD_BARCODE,
      payload
    );

    console.log("BBBBBB",response);
    

    if (response?.data?.result?.status_code === 200) {
      return response.data?.result;
    } else {
      return response;
    }
  } catch (error) {
    return error;
  }
};
