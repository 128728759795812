import React, { useEffect, useState } from "react";
import { Image } from "antd";
import {
  InventoryProductRequest,
  getUOM,
  uploadImage,
  uploadImageUrl,
} from "../../../API/API Functions/ProductRequest_API";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { InputBox } from "../../CustomWidgets/InputBox";
import SelectBox from "../../CustomWidgets/SelectBox";
import UploadMediaonly from "../../CustomWidgets/upload-imageonly";
import { toast } from "react-toastify";
import LoadingSpinner from "../../CustomWidgets/Laoding";
import { useNavigate } from "react-router-dom";
import BarcodeScanner from "react-barcode-reader";

const ProductRequest = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);
  const location = useLocation();
  const { fetchedProduct } = location.state || {};

  const [UOM, setUOM] = useState([]);
  const [isUOMLoaded, setIsUOMLoaded] = useState(false);
  const [base64Images, setBase64Images] = useState([]); // Store multiple images
  const [isFileValid, setIsFileValid] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [loading, setLoading] = useState(false);

  const [formValues, setFormValues] = useState({
    name: "",
    barcode: "",
    product_uom: "",
    costprice: "",
    saleprice: "",
    quantity: "",
    image_url: "",
    product_code: "",
    is_gs1: false,
  });

  useEffect(() => {
    if (base64Images.length > 0 && isFileValid === false) {
      setBase64Images([]);
      setFormValues((prev) => ({ ...prev, image_url: "" }));
    }
  }, [isFileValid, base64Images]);

  const get_UOM = async () => {
    const res = await getUOM();
    if (res?.result?.[0].status === "UOM List")
      setUOM(res?.result?.[0]?.uom_list);
    setIsUOMLoaded(true);
  };

  useEffect(() => {
    if (UOM?.length <= 0) {
      get_UOM();
    }
  }, [UOM]);

  useEffect(() => {
    if (isUOMLoaded && fetchedProduct?.gtin) {
      const uomId = UOM.find((uom) => uom.uom_name === "Units")?.uom_id;
      if (uomId) {
        setFormValues((prev) => ({
          ...prev,
          product_uom: uomId,
        }));
      }
    }
  }, [isUOMLoaded, fetchedProduct, UOM]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleImageUpload = (images) => {
    setBase64Images(images);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (formValues.name === "") {
      toast.error(t("Product name is required."));
      return;
    }
  
    if (formValues.quantity === "" || formValues.quantity === 0) {
      toast.error(t("Quantity is required."));
      return;
    }
  
    if (formValues.product_uom === "") {
      toast.error(t("Please Select Product UOM"));
      return;
    }
  
    if (formValues.costprice === "") {
      toast.error(t("Cost Price is required"));
      return;
    }
  
    if (formValues.saleprice === "") {
      toast.error(t("Sale Price is required"));
      return;
    }
  
    if (Number(formValues.costprice) > Number(formValues.saleprice)) {
      toast.error(t("Sale price cannot be less than cost price."));
      return;
    }
  
    if (formValues.product_code.length < 7 && formValues.product_code) {
      toast.error(t("Product code should not be less than 7 digits."));
      return;
    }
  
    setProcessing(true);
    setLoading(true);
  
    try {
      const costPrice = formValues?.costprice || 0;
      const salePrice = formValues?.saleprice || 0;
  
      const response = await InventoryProductRequest(
        user_id,
        formValues,
        costPrice,
        salePrice
      );
  
      if (response?.result[0]?.status_code === 200) {
        const productId = response.result[0].product_id;
  
        const imageUrls = [];
        if (base64Images.length > 0) {
          for (let i = 0; i < base64Images.length; i++) {
            const uploadedImageUrl = await uploadImage(
              productId,
              base64Images[i],
              i
            );
            if (uploadedImageUrl) {
              imageUrls.push(uploadedImageUrl);
            }
          }
        } else {
          // If no images are uploaded, use the default image URL
          imageUrls.push("https://assets.myginne.com/assets/retailer_profile_img/no-image.png");
        }
  
        const primaryImageUrl = imageUrls[0];
        const secondaryImageUrls = imageUrls.slice(1);
        const isImageUrlUploaded = await uploadImageUrl(
          productId,
          primaryImageUrl,
          secondaryImageUrls
        );
  
        if (isImageUrlUploaded) {
          toast.success(t("Product request submitted successfully"));
          setFormValues({
            name: "",
            barcode: "",
            product_uom: "",
            costprice: "",
            saleprice: "",
            quantity: "",
            image_url: "",
            product_code: "",
            is_gs1: false,
          });
          setBase64Images([]);
          navigate("/session");
          setLoading(false);
        } else {
          toast.error("Failed to upload image URLs.");
        }
      } else if (response?.result[0]?.status_code === 400) {
        toast.error("Product already exists.");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting product request:", error);
      toast.error("Failed to submit product request. Please try again.");
    } finally {
      setProcessing(false);
      setLoading(false);
    }
  };

  const handleCancel = () => {
    navigate("/session"); // Navigate to the /session route
  };

  return (
    <>
      {loading && <LoadingSpinner />}
      <form onSubmit={handleSubmit}>
        <div className="flex flex-col w-full md:w-[80%] h-[100%] mt-4 justify-center mx-auto p-4">
          <div className="h-10 bg-[#C9ECF7] rounded-t-xl flex items-center">
            <p className="ms-4 font-semibold text-xl">{t("Request Product")}</p>
          </div>

          <div className="flex flex-col md:flex-row flex-1 bg-white border rounded-b-xl">
            <div className="m-2 w-full md:w-2/4 p-4 text-xl">
              <InputBox
                name="name"
                label={t("Product Name")}
                placeholder={t("Enter Product Name")}
                onChange={handleChange}
                value={formValues.name}
              />

              <InputBox
                type="number"
                label={t("Barcode")}
                name="barcode"
                placeholder={t("Enter Barcode")}
                onChange={(event) => {
                  const { value } = event.target;
                  if (/^\d*$/.test(value)) {
                    setFormValues((prev) => ({ ...prev, barcode: value }));
                  }
                }}
                // onChange={handleChange}
                value={formValues.barcode}
                readOnly={!!fetchedProduct?.gtin}
              />

              <BarcodeScanner
                onScan={(data) => {
                  setFormValues((prev) => ({ ...prev, barcode: data }));
                }}
                onError={(err) => {
                  console.error(err);
                }}
                facingMode="environment"
                className="absolute inset-y-0 right-0 me-4 pointer-events-none cursor-pointer z-10"
              />

              <div className="flex flex-col md:flex-row gap-5 mt-2">
                <InputBox
                  type="text"
                  name="quantity"
                  label={t("Quantity")}
                  placeholder={t("Enter Quantity")}
                  // onChange={handleChange}
                  onChange={(event) => {
                    const { value } = event.target;

                    const selectedUom = UOM.find(
                      (uom) =>
                        uom.uom_name === "Units" &&
                        uom.uom_id === formValues.product_uom
                    );

                    if (
                      (selectedUom?.uom_name !== "Units" &&
                        /^\d*\.?\d{0,2}$/.test(value)) || // Allow decimals for non-units
                      (selectedUom?.uom_name === "Units" &&
                        /^\d{0,5}$/.test(value)) // Only whole numbers for units
                    ) {
                      if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                        setFormValues((prev) => ({ ...prev, quantity: value }));
                      }
                    }
                  }}
                  value={formValues.quantity}
                />
                <SelectBox
                  name="product_uom"
                  label={t("Product UOM")}
                  defaultValue=""
                  onChange={handleChange}
                  value={formValues.product_uom}
                  options={[
                    { value: "", label: t("Select"), disabled: true },
                    ...UOM?.map((item) => ({
                      value: item.uom_id,
                      label: t(item.uom_name),
                    })),
                  ]}
                />
              </div>
              {formValues.product_uom &&
                UOM.find(
                  (uom) =>
                    (uom?.uom_name === "KG" || uom.uom_name === "Liter") &&
                    uom?.uom_id === formValues?.product_uom
                ) && (
                  <InputBox
                    type="number"
                    name="product_code"
                    label={t("Product Code")}
                    placeholder={t("Enter Product Code")}
                    onChange={(event) => {
                      const { value } = event.target;
                      if (/^\d{0,7}$/.test(value)) {
                        setFormValues((prev) => ({
                          ...prev,
                          product_code: value,
                        }));
                      }
                    }}
                    // onBlur={formik.handleBlur}
                    value={formValues.product_code}
                    // error={formValues.product_code}
                    // touched={formValues.touched.product_code}
                  />
                )}

              <div className="flex flex-col md:flex-row gap-5 mt-2">
                <InputBox
                  type="number"
                  name="costprice"
                  label={t("Cost Price")}
                  placeholder={t("Enter Cost Price")}
                  onChange={(event) => {
                    const { value } = event.target;
                    if (
                      value === "" ||
                      /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(value)
                    ) {
                      if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                        setFormValues((prev) => ({
                          ...prev,
                          costprice: value,
                        }));
                      }
                    }
                  }}
                  value={formValues.costprice}
                />

                <InputBox
                  type="number"
                  label={t("Sale Price")}
                  name="saleprice"
                  placeholder={t("Enter Sale Price")}
                  onChange={(event) => {
                    const { value } = event.target;

                    if (
                      value === "" || // Allow empty input
                      /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(value) // Allow numbers with up to 6 digits before decimal and 2 after
                    ) {
                      if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                        setFormValues((prev) => ({
                          ...prev,
                          saleprice: value,
                        }));
                      }
                    }
                  }}
                  value={formValues.saleprice}
                />
              </div>

              <div className="flex items-center mt-8 gap-8">
                <button
                  type="submit" // Changed to type="submit"
                  disabled={processing}
                  className="w-56 h-12 mt-2 bg-blue-900 text-white text-l font-semibold rounded-lg flex items-center justify-center gap-2"
                >
                  {processing ? "Processing..." : t("Save")}
                </button>

                <button
                  type="button" // Changed to type="submit"
                  disabled={processing}
                  onClick={handleCancel}
                  className="w-56 h-12 mt-2 bg-white text-blue-900 border-blue-900 border text-l font-semibold rounded-lg flex items-center justify-center gap-2"
                >
                  {t("Cancel")}
                </button>
              </div>
            </div>

            <div className="m-2 w-full md:w-2/4 p-4">
              <div className="grid grid-cols-2 gap-4">
                {base64Images.map((image, index) => (
                  <div
                    key={index}
                    className="relative h-32 overflow-hidden border-2 rounded-lg"
                  >
                    <Image
                      src={image}
                      alt={`Uploaded ${index}`}
                      className="w-full h-32 object-contain rounded-lg"
                    />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        const newImages = [...base64Images];
                        newImages.splice(index, 1);
                        setBase64Images(newImages);
                      }}
                      className="absolute top-1 right-1 bg-red-500 text-white font-semibold rounded-lg w-8 h-8 flex items-center justify-center"
                    >
                      X
                    </button>
                  </div>
                ))}
              </div>
              {base64Images.length < 6 && (
                <UploadMediaonly
                  foldername={"store_image"}
                  onfileAdd={"Productrequest"}
                  setBase64Images={handleImageUpload}
                  multiple={true}
                  setIsFileValid={setIsFileValid}
                />
              )}
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default ProductRequest;
