import React, { useEffect, useRef, useState } from "react";
import { CashInOut } from "../../Api";
import { URLS } from "../../API/API endpoints";
import axiosInstance from "../../AxiosInstance";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import BarcodeScanner from "react-barcode-reader";
import { AddBarcodeApi } from "../../API/API Functions/ProductRequest_API";

function InputField({
  label,
  placeholder,
  value,
  onChange,
  type = "text",
  onEnterPress,
  inputRef,
}) {
  const { t, i18n } = useTranslation();
  // const inputRef = React.createRef();

  // React.useEffect(() => {
  //   inputRef.current.focus();
  // }, []);

  const handleKeyPress = (e) => {
    if (e.key === "Enter" && typeof onEnterPress === "function") {
      e.preventDefault();
      onEnterPress();
    }
  };

  return (
    <>
      <div className="mt-9 font-medium max-md:max-w-full">{t(label)}</div>
      <div
        className={`flex border border-solid rounded-xl overflow-hidden justify-center items-start ${
          i18n.language === "ar" ? "flex-row-reverse" : ""
        }`}
      >
        {/* {label === "Amount" && (
          <span className="pl-3 py-3 bg-white  text-black max-md:pe-3">
            EGP
          </span>
        )} */}
        <input
          //   ref={inputRef}
          readOnly
          type={type}
          placeholder={t(placeholder)}
          value={value}
          style={{ direction: "ltr" }}
          onChange={(e) => onChange(e.target.value)}
          onKeyPress={handleKeyPress}
          className="p-3 bg-white rounded-xl text-left border-0 border-neutral-400 border-opacity-50 text-black max-md:pr-5 max-md:max-w-full w-full focus:outline-none focus:border-2px"
        />
      </div>
    </>
  );
}

function AddProductBarcode({
  isOpen,
  onClose,
  prod_id,
  onBarcodeAdded,
  setIsAddBarcodeModalOpen,
}) {
  const { t, i18n } = useTranslation();
  const user_id = useSelector((state) => state?.login?.user?.result?.user_id);

  const [restricted, setrestricted] = useState(false);
  const [barcode, setBarcode] = useState("");
  const [barcodeError, setBarcodeError] = useState("");
  const [clickedButton, setClickedButton] = useState(""); // Track clicked button
  const [isHovered, setIsHovered] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      setBarcode(""); // Reset the barcode value when the modal opens
      setBarcodeError(""); // Optionally reset any error messages
    }
    setIsAddBarcodeModalOpen(isOpen);
  }, [isOpen]);

  const handleBarcodeChange = (e) => {
    const value = e.target.value;

    if (/^\d*$/.test(value)) {
      setBarcode(value);
      setBarcodeError("");
      // } else {
      //   setBarcodeError(
      //     "Barcode must be a whole number without decimals or hyphens."
      //   );
    }
  };

  const handleSubmit = (e) => {
    // e.preventDefault();

    if (!barcode) {
      return;
    }

    // console.log("ORDER ID", bill_id);
    setrestricted(true);
    setLoading(true);
    const payload = {
      product_id: prod_id,
      barcode: Number(barcode),
    };

    AddBarcodeApi(payload).then((response) => {
      //   console.log("AAAAAAA", response);

      //   123214213213

      if (
        response?.data?.result[0]?.status_code === 400 &&
        response?.data?.result[0]?.status ===
          "Entered Barcode already assigned to other product"
      ) {
        toast.error(t("Barcode already assigned to other product"));
        setLoading(false);
        return;
      }

      if (
        response?.data?.result[0]?.status_code === 200 &&
        response?.data?.result[0]?.status === "Barcode Updated"
      ) {
        toast.success(t("Barcode Updated Successfully"));
        onBarcodeAdded(barcode);
        setBarcode("");
        setLoading(false);
        onClose();

        // Popup();
      }
    });
    // console.log("hyyyyyy>>>>>>>>>>>>>>");
    // onClose();
  };

  const handleRemoveBarcode = () => {
    setClickedButton("remove"); // Track the button click
    setBarcode(""); // Clear the barcode input field
  };

  const handleClose = () => {
    onClose();
  };

  //   const handleKeyDown = (e) => {
  //     if (e.key === "Escape") {
  //       onClose();
  //     }

  //     if (e.key === "Enter" && !hasConfirmed && !isTransactionInProgress) {
  //       setIshasConfirmed(true);
  //       setIsTransactionInProgress(true);
  //       handleConfirm();
  //     }
  //   };

  //   useEffect(() => {
  //     window.addEventListener("keydown", handleKeyDown);
  //     return () => {
  //       window.removeEventListener("keydown", handleKeyDown);
  //     };
  //   }, [amount, cashDifference, isTransactionInProgress, hasConfirmed]);

  return (
    <>
      <BarcodeScanner
        onScan={(data) => {
        //   console.log("BArcode Data", data);

          setBarcode(data); // Set the scanned barcode value
        }}
        onError={(err) => {
          console.error(err);
        }}
        facingMode="environment"
        className="absolute inset-y-0 right-0 me-4 pointer-events-none cursor-pointer z-10"
      />
      {isOpen && (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50  backdrop-blur-md drop-shadow-sm">
          <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
          <section
            className=" relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[25px] text-zinc-800 max-md:px-5 z-50"
            style={{ width: "600px" }}
          >
            <header className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
              <h1 className="flex-auto my-auto">{t("Add Barcode")}</h1>
              <button
                type="button"
                onClick={handleClose}
                className="absolute top-4 right-3 text-zinc-800"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="w-6 h-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </header>
            <main>
              <InputField
                label=""
                placeholder="Enter Barcode"
                value={barcode}
                // onEnterPress={() => {
                //   title === "Cash Out" && parseFloat(amount) > -cashDifference
                //     ? toast.error(t("Not enough cash"))
                //     : handleConfirm();
                // }}
                onChange={handleBarcodeChange}
                type="text"
                readOnly
              />
            </main>
            <footer>
              <button
                disabled={!barcode}
                className={`justify-center items-center px-16 py-3.5 mt-16 text-xl text-center text-white capitalize whitespace-nowrap bg-[#7EC242] rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full`}
                style={{ width: "500px", opacity: barcode ? 1 : 0.5 }}
                onClick={() => handleSubmit()}
              >
                {t("Confirm")}
              </button>
            </footer>
          </section>
        </div>
      )}
    </>
  );
}

export default AddProductBarcode;
