import * as React from "react";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { useSelector, useDispatch } from "react-redux";
import {
  bank_icon_2,
  bank_method_icon,
  cash_bank_icon,
  cash_icon_2,
  cash_method_icon,
  clear_icon,
  credit,
  credit_blue,
  delivery_icon,
  return_icon,
} from "../../assets";
import { createOrder } from "../../Redux/Action/createposorderAction";
import { useTranslation } from "react-i18next";
import { storeSelectedPaymentMethod } from "../../Redux/Action/paymentAction";
import { toast } from "react-toastify";
// import LoadingSpinner from './LoadingSpinner'; // Import LoadingSpinner component

const LoadingSpinner = () => {
  return (
    <div
      style={{ zIndex: 50 }}
      className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50"
    >
      <div className="spinner">
        <span></span>
        <span></span>
        <span></span>
      </div>
    </div>
  );
};

// const LoadingSpinner = () => {
//     return (
//       <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 bg-black bg-opacity-50">
//         <div className="spinner">
//           <span></span>
//           <span></span>
//           <span></span>
//         </div>
//       </div>
//     );
//   };

function PaymentButton({ paymentMethod, selected, onClick, disabled }) {
  const { t, i18n } = useTranslation();

  const getIcon = (method) => {
    switch (method) {
      case "Cash":
        // return cash_method_icon;
        return cash_icon_2;
      case "Bank":
        return bank_icon_2;
      // return bank_method_icon;
      case "Delivery":
        return delivery_icon;
      case "Cash & Bank":
        return cash_bank_icon;
      case "Credit":
        return credit_blue;

      default:
        return null;
    }
  };

  const getPaymentMethodName = (method) => {
    switch (method) {
      case "Cash":
        return t("Cash");
      case "Bank":
        return t("Bank");
      case "Delivery":
        return t("Delivery");
      case "Cash & Bank":
        return t("Cash & Bank");
      case "Credit":
        return "Credit";
      default:
        return null;
    }
  };
  const getButtonWidth = (method) => {
    switch (method) {
      case "Cash":
        return "39%";
      case "Delivery":
        return "56%";
      case "Bank":
        return "46%";
      case "Cash & Bank":
        return "56%";
      case "Credit":
        return "100%";
      default:
        return "48%";
    }
  };

  return (
    <div
      className={`flex items-center justify-center gap-2 mt-2 px-8 py-2 text-l font-semibold rounded-lg border-2 border-solid shadow-sm cursor-pointer whitespace-nowrap ${
        selected
          ? "text-blue-900 bg-white border-blue-900"
          : "bg-white border border-solid border-stone-300 text-zinc-600"
      } ${disabled ? "opacity-50 cursor-not-allowed" : ""}`}
      onClick={onClick}
      disabled={disabled}
      style={{ width: getButtonWidth(paymentMethod.name) }}
    >
      {getIcon(paymentMethod.name) && (
        <img
          src={getIcon(paymentMethod.name)}
          alt={`${paymentMethod.name} icon`}
          className={`w-10  ${selected ? "" : "filter grayscale"}`}
        />
      )}
      {getPaymentMethodName(paymentMethod.name)}
    </div>
  );
}

function KeypadButton({
  value,
  className,
  onClick,
  children,
  isBankPaymentSelected,
  grandTotal,
  selectedPaymentMethod,
}) {
  return (
    <button
      type="button"
      className={`flex justify-center items-center h-[94px] w-40 rounded ${
        isBankPaymentSelected ||
        grandTotal < 0 ||
        selectedPaymentMethod?.name == "Delivery" ||
        selectedPaymentMethod?.name == "Credit"
          ? "opacity-50"
          : ""
      } ${className}`}
      onClick={
        isBankPaymentSelected || selectedPaymentMethod?.name == "Delivery"
          ? null
          : onClick
      }
      disabled={
        isBankPaymentSelected ||
        grandTotal < 0 ||
        selectedPaymentMethod?.name == "Delivery" ||
        selectedPaymentMethod?.name == "Credit"
      }
    >
      {children || value}
    </button>
  );
}
const dummyPaymentMethods = [
  // { name: "Cash" },
  // { name: "Bank" },
  { name: "Delivery" },
  { name: "Cash & Bank" },
  // { name: "Credit" },
];

function Payment_Modal({
  onClose,
  grandTotal,
  handleCheckout,
  setTenderCash,
  creditAmount,
  deliveryCheck,
  deliveryFee,
  setDeliveryFee,
  returnDeliveryFee,
  setReturnDeliveryFee,
  showReturnDelivery,
  constReturnDeliveryFee,
}) {
  const { t, i18n } = useTranslation();
  const { user } = useSelector((state) => state?.login);
  const [totalPaid, setTotalPaid] = useState("");
  const [ReturnTotal, setReturnTotal] = useState("");
  const [change, setChange] = useState("");
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState();
  const [isBankPaymentSelected, setIsBankPaymentSelected] = useState(false);
  // const [paymentMethods, setPaymentMethods] = useState([]);
  const [paymentMethods, setPaymentMethods] = useState(dummyPaymentMethods);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [activePaymentIndex, setActivePaymentIndex] = useState(0);
  const returnProducts = useSelector(
    (state) => state.returnProductReducer.products
  );

  // const [deliveryFee, setDeliveryFee] = useState(0); // State for delivery fee

  const [cashPaid, setCashPaid] = useState(0);
  const [remainingAmount, setRemainingAmount] = useState(grandTotal);
  const totalPaidInputRef = React.useRef(null);
  const getCustomerState = useSelector(
    (state) => state?.getcustomer?.selectedCustomer
  );
  // const [creditAmount, setCreditAmount] = useState("");

  const dispatch = useDispatch();

  // console.log("getCustomerState--->", getCustomerState);

  const getPaymentMethod = async (userID) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(URLS.PAYMENT_METHODS, {
        user_id: userID,
      });
      if (response?.data.result.data != null) {
        const methods = response.data.result.data.map((method) => ({
          name: method.name,
          id: method.id,
        }));
        setPaymentMethods([...methods, ...dummyPaymentMethods]);
        const cashMethod = methods.find((method) => method.name === "Cash");
        if (cashMethod) {
          setSelectedPaymentMethod(cashMethod);
          setActivePaymentIndex(0);
        }
      }
    } catch (err) {
      console.error(err);
      setErrorMessage("Failed to fetch payment methods.");
    } finally {
      setLoading(false);
    }
  };

  // const getCreditAomunt = async () => {
  //   try {
  //     setLoading(true);
  //     const response = await axiosInstance.post(URLS.GET_CUSTOMER_CREDIT, {
  //       phone: getCustomerState,
  //     });

  //     console.log("CREDIT RES--->", response);

  //     if (response?.data?.result[0].status_code === 200 && response?.data?.result[0].status === "Customer Credits" ) {
  //       setCreditAmount( response?.data?.result[0]);
  //     // console.log("CREDIT Amount--->", creditAmount);

  //     }
  //   } catch (err) {
  //     console.error(err);
  //     setErrorMessage("Failed to fetch credit amount.");
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  useEffect(() => {
    if (!deliveryCheck) {
      getPaymentMethod(user?.result?.user_id);
    }

    // if (getCustomerState) {
    //   getCreditAomunt();
    // }
  }, [user?.result?.user_id, getCustomerState]);

  const keypadValues = [
    [
      {
        value: "1",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "2",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "3",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "10",
        className:
          "text-white bg-[#7EC242]  border border-[#7EC242] hover:bg-[#7EC242]",
        // "text-white  bg-gradient-to-b from-[#97DD59] via-[#97DD59] to-[#6BB12C]  border border-[#97DD59] hover:bg-lime-600",
      },
    ],
    [
      {
        value: "4",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "5",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "6",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "20",
        className:
          "text-white bg-[#7EC242]  border border-[#7EC242] hover:bg-[#7EC242]",
        // "text-white  bg-gradient-to-b from-[#97DD59] via-[#97DD59] to-[#6BB12C]  border border-[#97DD59] hover:bg-lime-600",
      },
    ],
    [
      {
        value: "7",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "8",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "9",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "50",
        className:
          "text-white bg-[#7EC242]  border border-[#7EC242] hover:bg-[#7EC242]",
        // "text-white  bg-gradient-to-b from-[#97DD59] via-[#97DD59] to-[#6BB12C]  border border-[#97DD59] hover:bg-lime-600",
      },
    ],
    [
      {
        value: ".",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "0",
        className:
          "bg-gradient-to-t from-[#EEEEEE] via-[#FFFFFF] to-[#EEEEEE] border border-gray-300 hover:bg-gray-200",
      },
      {
        value: "200",
        className:
          "text-white bg-[#7EC242]  border border-[#7EC242] hover:bg-[#7EC242]",
        // "text-white  bg-gradient-to-b from-[#97DD59] via-[#97DD59] to-[#6BB12C]  border border-[#97DD59] hover:bg-lime-600",
      },
      {
        value: "100",
        className:
          "text-white bg-[#7EC242]  border border-[#7EC242] hover:bg-[#7EC242]",
        // "text-white  bg-gradient-to-b from-[#97DD59] via-[#97DD59] to-[#6BB12C]  border border-[#97DD59] hover:bg-lime-600",
      },
    ],
  ];

  const handleKeypadButtonClick = (value) => {
    if (
      totalPaid.length >= 10 &&
      value !== "C" &&
      value !== "." &&
      !["50", "100", "200"].includes(value)
    ) {
      return;
    }

    if (value === "C") {
      setTotalPaid("");
      setTenderCash("");
      setChange("");
    } else if (value === ".") {
      if (!totalPaid.includes(".")) {
        setTotalPaid((prevTotalPaid) => prevTotalPaid + ".");
        setTenderCash((prevTotalPaid) => prevTotalPaid + ".");
      }
    } else if (!isNaN(parseInt(value, 10))) {
      if (["50", "100", "200", "20", "10"].includes(value)) {
        const updatedTotalPaid = parseFloat(totalPaid || 0) + parseFloat(value);
        setTotalPaid(updatedTotalPaid.toString());
        setTenderCash(updatedTotalPaid.toString());
        const changeNumeric =
          updatedTotalPaid -
          parseFloat(String(grandTotal).replace(/[^0-9.]/g, ""));
        setChange(`${Math.max(changeNumeric, 0)}`);
      } else {
        const updatedTotalPaid = totalPaid + value;
        setTotalPaid(updatedTotalPaid);
        setTenderCash(updatedTotalPaid);
        const numericValue = parseFloat(
          updatedTotalPaid.replace(/[^0-9.]/g, "")
        );
        const changeNumeric =
          numericValue - parseFloat(String(grandTotal).replace(/[^0-9.]/g, ""));
        setChange(`${Math.max(changeNumeric, 0)}`);
      }

      if (selectedPaymentMethod?.name === "Cash & Bank") {
        const updatedTotalPaid = totalPaid + value;
        const numericValue = parseFloat(
          updatedTotalPaid.replace(/[^0-9.]/g, "")
        );

        const remainingAmount = grandTotal - numericValue;
        setRemainingAmount(Math.max(remainingAmount, 0)); // Ensure it's not negative
      }
    }
    setErrorMessage("");
  };

  const handlePaymentMethodClick = (method) => {
    if (grandTotal <= 0 && method.name === "Cash") setTotalPaid(0);
    if (method.name === "Delivery") {
      setDeliveryFee(0); // Reset delivery fee when selecting Delivery
    }else{
      setDeliveryFee(null);
    }
    if (
      (grandTotal <= 0 && method.name === "Bank") ||
      (grandTotal <= 0 && method.name === "Cash & Bank") ||
      (grandTotal <= 0 && method.name === "Delivery")
    )
      return;
    dispatch(storeSelectedPaymentMethod(method));
    setSelectedPaymentMethod(method);
    setActivePaymentIndex(paymentMethods.indexOf(method));

    if (method.name === "Cash & Bank") {
      const cashMethod = paymentMethods.find((m) => m.name === "Cash");
      const bankMethod = paymentMethods.find((m) => m.name === "Bank");

      if (cashMethod && bankMethod) {
        // Set the selected payment method to include both IDs
        method = {
          name: method.name,
          id: {
            cashId: cashMethod.id,
            bankId: bankMethod.id,
          },
        };
        setSelectedPaymentMethod(method);
      }
      setIsBankPaymentSelected(false);
      setCashPaid(0); // Reset cash paid
      setRemainingAmount(grandTotal); // Set remaining amount to grand total
      setTotalPaid(""); // Clear total paid input
      setChange(""); // Clear change
    } else if (method.name === "Bank") {
      setIsBankPaymentSelected(true);
      setTenderCash(String(grandTotal));
      setTotalPaid(String(grandTotal));
      setChange("0");
    } else if (method.name === "Delivery") {
      const cashMethod = paymentMethods.find((m) => m.name === "Cash");
      if (cashMethod) {
        method = {
          name: method.name,
          id: cashMethod.id, // Pass the ID of the Cash method
        };
        setSelectedPaymentMethod(method);
      }

      setIsBankPaymentSelected(false);
      // setTenderCash(String(grandTotal));
      setTotalPaid(0);
      setTenderCash(0);
      setChange("0");
    } else if (method.name === "Credit") {
      const cashMethod = paymentMethods.find((m) => m.name === "Cash");
      if (cashMethod) {
        method = {
          name: method.name,
          id: cashMethod.id, // Pass the ID of the Cash method
        };
        setSelectedPaymentMethod(method);
      }

      setIsBankPaymentSelected(false);
      // setTenderCash(String(grandTotal));
      setTotalPaid(0);
      setTenderCash(0);
      setChange("0");
    } else {
      setIsBankPaymentSelected(false);
      setTenderCash("");
      setTotalPaid("");
      setChange("");
    }
    setErrorMessage("");
  };

  const handleCheckoutClick = (event) => {
    // console.log("SELECTED PM", selectedPaymentMethod);
    if (errorMessage) {
      toast.error(
        t(`The Return Delivery Fee cannot exceed EGP ${constReturnDeliveryFee}`)
      );
      return;
    }

    handleCheckout(selectedPaymentMethod);
  };

  // console.log("selectedPaymentMethod--->", selectedPaymentMethod);

  const handleClearButtonClick = () => {
    if (!isBankPaymentSelected) {
      setTotalPaid("");
      setChange("");
      setRemainingAmount(0);
    }
  };

  useEffect(() => {
    if (grandTotal < 0) {
      setTotalPaid(grandTotal.toString());
      setIsBankPaymentSelected(true);
    }
  }, [grandTotal]);

  const handleKeyPress = (event) => {
    const normalizedKey =
      {
        // ش: "Enter", // Arabic "ش" is similar to English "Enter"
        x: "ء", // Arabic "Ctrl+X" will be normalized to "x" when Ctrl is pressed
      }[event.key] || event.key; // Fallback to the original key if no mapping exists

    if (event.key === "Enter") {
      event.preventDefault();
      if (
        selectedPaymentMethod?.name === "Cash & Bank" &&
        totalPaid >= grandTotal
      ) {
        return;
      }
      if (
        (selectedPaymentMethod?.name === "Cash & Bank" && totalPaid > 0) ||
        (selectedPaymentMethod?.name === "Cash" && totalPaid >= grandTotal)
      ) {
        handleCheckoutClick(event);
      } else if (
        selectedPaymentMethod?.name === "Bank" &&
        totalPaid == grandTotal
      ) {
        handleCheckoutClick(event);
      } else if (selectedPaymentMethod?.name === "Delivery") {
        handleCheckoutClick(event);
      } else if (selectedPaymentMethod?.name === "Credit") {
        handleCheckoutClick(event);
      }
      // if (totalPaid > grandTotal || totalPaid == grandTotal)
      //   handleCheckoutClick(event);
    } else if (event.shiftKey && normalizedKey === "Shift") {
      event.preventDefault();
      if (
        (grandTotal <= 0 && selectedPaymentMethod?.name === "Bank") ||
        grandTotal <= 0
      ) {
        return;
      }

      const nextIndex = (activePaymentIndex + 1) % paymentMethods.length;
      setActivePaymentIndex(nextIndex);
      setSelectedPaymentMethod(paymentMethods[nextIndex]);
      handlePaymentMethodClick(paymentMethods[nextIndex]);
    } else if (event.ctrlKey && normalizedKey === "ء") {
      // Handle Ctrl+X
      event.preventDefault();
      handleClearButtonClick();
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [
    totalPaid,
    grandTotal,
    selectedPaymentMethod,
    activePaymentIndex,
    handleClearButtonClick,
  ]);

  useEffect(() => {
    if (
      selectedPaymentMethod?.name === "Cash" ||
      selectedPaymentMethod?.name === "Cash & Bank"
    ) {
      totalPaidInputRef.current?.focus(); // Focus the input field
    }
  }, [selectedPaymentMethod]);

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
      {loading && <LoadingSpinner />}

      <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
      <section
        className="relative flex flex-col px-12 py-10 text-lg font-semibold  bg-white rounded-[15px] text-zinc-800 max-md:px-5 z-40"
        style={{ width: "1120px" }}
      >
        <button
          type="button"
          onClick={onClose}
          className="absolute top-4 right-3 text-zinc-800"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
        <main>
          <div className="flex flex-1 gap-10 max-md:flex-col">
            <div className="flex flex-col w-1/3 max-md:w-full">
              <div className="flex flex-col gap-3">
                <div className="text-2xl font-semibold tracking-wider text-[#5C5C5C]">
                  {t("Grand Total")}
                </div>

                <div className="text-2xl font-bold mt-5 tracking-wider text-[#5C5C5C] mt-2">
                  {/* {t("Total Paid")} */}
                  {selectedPaymentMethod?.name === "Cash & Bank"
                    ? t("Total paid by cash")
                    : t("Total Paid")}
                </div>
                {grandTotal > 0 && (
                  <div className="text-2xl font-bold  mt-5 tracking-wider text-[#5C5C5C] mt-2">
                    {/* {t("Change")} */}
                    {selectedPaymentMethod?.name === "Cash & Bank"
                      ? t("Total paid by bank")
                      : t("Change")}
                  </div>
                )}
                {creditAmount?.response && (
                  <div className="text-2xl font-bold  mt-5 tracking-wider text-[#5C5C5C] mt-2">
                    {t("Credit Amount")}
                  </div>
                )}

                {showReturnDelivery && (
                  <div className="text-2xl font-bold  mt-5 tracking-wider text-[#5C5C5C] mt-2">
                    {t("Refund Delivery Fee")}
                  </div>
                )}

                {selectedPaymentMethod?.name === "Delivery" && (
                  <div className="text-2xl font-bold  mt-5 tracking-wider text-[#5C5C5C] mt-2">
                    {t("Delivery Fee")}
                  </div>
                )}

                <div className="flex flex-col capitalize max-md:flex-wrap">
                  <span className="mt-6 text-gray-600 italic">
                    {t("Payment Options")}
                  </span>
                  <div className="flex justify-center gap-4 w-full">
                    {paymentMethods.map((method, index) => {
                      if (
                        method.name === "Cash" ||
                        method.name === "Delivery"
                      ) {
                        return (
                          <PaymentButton
                            key={method.id} // Use the id as the key
                            paymentMethod={method}
                            selected={
                              selectedPaymentMethod?.name === method.name ||
                              activePaymentIndex === index
                            }
                            onClick={() =>
                              handlePaymentMethodClick({
                                name: method.name,
                                id: method.id,
                              })
                            }
                            disabled={
                              (grandTotal <= 0 && method.name === "Delivery") ||
                              (grandTotal <= 0 && method.name === "Cash & Bank")
                            }
                          />
                        );
                      }
                      return null; // Skip rendering for other methods in this row
                    })}
                  </div>

                  {/* Second row: Bank and Cash & Bank */}
                  <div className="flex justify-center gap-4 w-full">
                    {paymentMethods.map((method, index) => {
                      if (
                        method.name === "Bank" ||
                        method.name === "Cash & Bank"
                      ) {
                        return (
                          <PaymentButton
                            key={method.id} // Use the id as the key
                            paymentMethod={method}
                            selected={
                              selectedPaymentMethod?.name === method.name ||
                              activePaymentIndex === index
                            }
                            onClick={() =>
                              handlePaymentMethodClick({
                                name: method.name,
                                id: method.id,
                              })
                            }
                            disabled={
                              (grandTotal <= 0 && method.name === "Bank") ||
                              grandTotal <= 0
                            }
                          />
                        );
                      }
                      return null; // Skip rendering for other methods in this row
                    })}
                  </div>
                  <div className="flex justify-center gap-4 w-full">
                    {paymentMethods.map((method, index) => {
                      if (
                        // method.name === "Bank" ||
                        method.name === "Credit"
                      ) {
                        return (
                          <PaymentButton
                            key={method.id} // Use the id as the key
                            paymentMethod={method}
                            selected={
                              selectedPaymentMethod?.name === method.name ||
                              activePaymentIndex === index
                            }
                            onClick={() =>
                              handlePaymentMethodClick({
                                name: method.name,
                                id: method.id,
                              })
                            }
                            disabled={
                              (grandTotal <= 0 && method.name === "Bank") ||
                              grandTotal <= 0
                            }
                          />
                        );
                      }
                      return null; // Skip rendering for other methods in this row
                    })}
                  </div>
                </div>

                {/* Checkout Button  */}

                {/* {errorMessage && (
                  <div className="text-red-500 text-center mt-8">
                    {errorMessage}
                  </div>
                )} */}
                <button
                  disabled={
                    (selectedPaymentMethod?.name === "Cash & Bank" &&
                      totalPaid <= 0) ||
                    (selectedPaymentMethod?.name === "Cash" &&
                      totalPaid < grandTotal) ||
                    (selectedPaymentMethod?.name === "Cash & Bank" &&
                      totalPaid >= grandTotal)
                  }
                  onClick={handleCheckoutClick}
                  className="justify-center items-center py-[33%] mt-2  text-3xl text-center text-white capitalize whitespace-nowrap bg-[#1b4597] rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
                  style={{
                    opacity:
                      (selectedPaymentMethod?.name === "Cash & Bank" &&
                        totalPaid <= 0) ||
                      (selectedPaymentMethod?.name === "Cash & Bank" &&
                        totalPaid >= grandTotal) ||
                      (selectedPaymentMethod?.name === "Cash" &&
                        totalPaid < grandTotal)
                        ? 0.5
                        : 1,
                  }}
                >
                  {t("Done")}
                </button>
              </div>
            </div>

            <div className="flex flex-col w-2/3 max-md:w-full">
              {/* Grand Total  */}

              {/* Tota Paid  */}
              <div className="flex flex-row gap-8">
                <div>
                  <div
                    className={`flex h-[40px] overflow-hidden justify-center items-start ${
                      i18n.language === "ar" ? "flex-row-reverse " : ""
                    }`}
                  >
                    <span className="inset-y-0 flex items-center pl-3 text-2xl text-black">
                      EGP
                    </span>
                    <input
                      type="text"
                      className="text-left  w-full px-2 font-bold text-zinc-800 text-2xl"
                      // value={`${grandTotal}`}
                      // value={parseFloat(grandTotal).toFixed(2)}
                      value={
                        i18n.language === "ar" && grandTotal < 0
                          ? `${-grandTotal.toFixed(2)}-`
                          : parseFloat(grandTotal).toFixed(2)
                      }
                      readOnly
                    />
                  </div>
                  <div
                    // className={`flex bg-white mt-1 rounded-lg border border-solid border-stone-300 h-[60px] overflow-hidden justify-center items-start ${
                    //   i18n.language === "ar" ? "flex-row-reverse" : ""
                    // }`}
                    className={`flex bg-white mt-1 rounded-lg h-[60px] overflow-hidden justify-center items-start ${
                      isBankPaymentSelected ||
                      grandTotal < 0 ||
                      selectedPaymentMethod?.name === "Delivery" ||
                      selectedPaymentMethod?.name === "Credit"
                        ? "" // No border class
                        : "border border-solid border-stone-300" // Apply border class
                    } ${i18n.language === "ar" ? "flex-row-reverse" : ""}`}
                  >
                    <span className="inset-y-0 flex items-center pl-3 py-3.5 text-2xl text-black">
                      EGP
                    </span>
                    <input
                      ref={totalPaidInputRef}
                      type="text"
                      autoFocus={true}
                      className="bg-white text-left h-[62px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                      value={
                        isBankPaymentSelected
                          ? grandTotal.toFixed(2)
                          : grandTotal <= 0
                          ? "0"
                          : totalPaid
                      }
                      readOnly={
                        isBankPaymentSelected ||
                        grandTotal < 0 ||
                        selectedPaymentMethod?.name == "Delivery" ||
                        selectedPaymentMethod?.name == "Credit"
                      }
                      style={{ direction: "ltr" }}
                      onChange={(e) => {
                        // Get the input value
                        let inputValue = e.target.value;

                        // Replace Arabic decimal separator with a period
                        inputValue = inputValue.replace(/ز/g, ".");

                        // Remove any non-numeric characters except the decimal point
                        const sanitizedValue = inputValue.replace(
                          /[^0-9.]/g,
                          ""
                        );

                        // Validate the sanitized value
                        if (/^\d*\.?\d{0,2}$/.test(sanitizedValue)) {
                          setTotalPaid(sanitizedValue);
                          setTenderCash(sanitizedValue);

                          // Calculate the change
                          const numericValue = parseFloat(sanitizedValue) || 0;
                          const grandTotalValue =
                            parseFloat(
                              String(grandTotal).replace(/[^0-9.]/g, "")
                            ) || 0;
                          const changeNumeric = numericValue - grandTotalValue;

                          // Set the change ensuring it's not negative
                          setChange(`${Math.max(changeNumeric, 0)}`);

                          if (selectedPaymentMethod?.name === "Cash & Bank") {
                            const remainingAmount =
                              grandTotalValue - numericValue;
                            setRemainingAmount(Math.max(remainingAmount, 0)); // Ensure it's not negative
                          }
                        }
                      }}
                      maxLength={7}
                    />
                  </div>
                  {grandTotal > 0 && (
                    <div
                      className={`flex rounded-lg mt-2  w-[300px] h-[60px] overflow-hidden justify-center items-start ${
                        i18n.language === "ar" ? "flex-row-reverse ms-10" : ""
                      }`}
                    >
                      <span className="inset-y-0 flex items-center pl-3 py-3.5 text-2xl text-black">
                        EGP
                      </span>

                      <input
                        type="text"
                        className="text-left h-[62px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                        value={
                          selectedPaymentMethod?.name === "Cash & Bank"
                            ? `${remainingAmount.toFixed(2)}`
                            : i18n.language === "ar" && grandTotal < 0
                            ? `${-grandTotal.toFixed(2)}-`
                            : grandTotal < 0
                            ? grandTotal.toFixed(2) // Display grandTotal when it's less than 0
                            : Number(change)?.toFixed(2) // Format change with two decimal places
                        }
                        readOnly
                      />
                    </div>
                  )}
                  {creditAmount?.response && (
                    <div
                      className={`flex rounded-lg mt-2  w-[300px] h-[60px] overflow-hidden justify-center items-start ${
                        i18n.language === "ar" ? "flex-row-reverse ms-10" : ""
                      }`}
                    >
                      <span className="inset-y-0 flex items-center pl-3 py-3.5 text-2xl text-black">
                        EGP
                      </span>

                      <input
                        type="text"
                        className="text-left h-[62px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                        value={creditAmount?.response}
                        readOnly
                      />
                    </div>
                  )}

                  {showReturnDelivery && (
                    <div
                      className={`flex bg-white mt-1 rounded-lg border border-solid  h-[60px] overflow-hidden justify-center items-start ${
                        i18n.language === "ar"
                          ? "flex-row-reverse"
                          : "" || errorMessage
                          ? "border-red-500"
                          : "border-stone-300"
                      }`}
                    >
                      <span className="inset-y-0 flex items-center pl-3 py-3.5 text-2xl text-black">
                        EGP
                      </span>
                      <input
                        type="text"
                        value={returnDeliveryFee}
                        className="bg-white text-left h-[62px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                        style={{ direction: "ltr" }}
                        onChange={(e) => {
                          // Get the input value
                          let inputValue = e.target.value;

                          // Replace Arabic decimal separator with a period
                          inputValue = inputValue.replace(/ز/g, ".");

                          // Remove any non-numeric characters except the decimal point
                          const sanitizedValue = inputValue.replace(
                            /[^0-9.]/g,
                            ""
                          );

                          // Validate the sanitized value
                          if (/^\d*\.?\d{0,2}$/.test(sanitizedValue)) {
                            // Allow leading zero for decimal numbers
                            let numericValue = sanitizedValue;

                            // If the input starts with a decimal point, prepend a zero
                            if (numericValue.startsWith(".")) {
                              numericValue = "0" + numericValue;
                            }

                            // Trim leading zeros, but keep '0' if the input is just '0'
                            numericValue =
                              numericValue.replace(/^0+(?=\d)/, "") || "0";

                            // Check if the value exceeds returnDeliveryFee
                            if (
                              parseFloat(numericValue) > constReturnDeliveryFee
                            ) {
                              setErrorMessage(
                                `Value cannot exceed ${constReturnDeliveryFee}`
                              );
                            } else {
                              setErrorMessage(""); // Clear error message
                            }

                            // Update the return delivery fee
                            setReturnDeliveryFee(numericValue);
                          }
                        }}
                        onBlur={() => {
                          // Clear the input field if the value is 0
                          if (returnDeliveryFee === 0) {
                            setReturnDeliveryFee(0);
                          }
                        }}
                        maxLength={7}
                      />
                    </div>
                  )}

                  {selectedPaymentMethod?.name === "Delivery" && (
                    // <div className="flex flex-col mt-4">
                    //   <input
                    //     type="number"
                    //     value={deliveryFee}
                    //     onChange={(e) => setDeliveryFee(Number(e.target.value))}
                    //     className="border rounded-md p-2"
                    //     placeholder="0"
                    //   />
                    // </div>

                    <div
                      className={`flex bg-white mt-1 rounded-lg border border-solid border-stone-300 h-[60px] overflow-hidden justify-center items-start ${
                        i18n.language === "ar" ? "flex-row-reverse" : ""
                      }`}
                    >
                      <span className="inset-y-0 flex items-center pl-3 py-3.5 text-2xl text-black">
                        EGP
                      </span>
                      <input
                        // ref={totalPaidInputRef}
                        type="text"
                        autoFocus={true}
                        value={deliveryFee}
                        className="bg-white text-left h-[62px] w-full focus:outline-none px-2 font-bold text-zinc-800 text-2xl"
                        style={{ direction: "ltr" }}
                        onChange={(e) => {
                          // Get the input value
                          let inputValue = e.target.value;

                          // Replace Arabic decimal separator with a period
                          inputValue = inputValue.replace(/ز/g, ".");

                          // Remove any non-numeric characters except the decimal point
                          const sanitizedValue = inputValue.replace(
                            /[^0-9.]/g,
                            ""
                          );

                          // Validate the sanitized value
                          if (/^\d*\.?\d{0,2}$/.test(sanitizedValue)) {
                            setDeliveryFee(sanitizedValue);
                          }
                        }}
                        maxLength={7}
                      />
                    </div>
                  )}
                </div>

                <button
                  className={`justify-center items-center text-xl text-white bg-[#F42020] mt-11 w-[500px] h-[60px] rounded-lg border border-solid border-stone-300 max-md:px-5 ${
                    isBankPaymentSelected ||
                    selectedPaymentMethod?.name === "Credit" ||
                    selectedPaymentMethod?.name === "Delivery" ||
                    grandTotal < 0
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                  onClick={
                    isBankPaymentSelected ||
                    selectedPaymentMethod?.name === "Credit" ||
                    selectedPaymentMethod?.name === "Delivery"
                      ? null
                      : handleClearButtonClick
                  }
                  style={{
                    opacity:
                      isBankPaymentSelected ||
                      selectedPaymentMethod?.name === "Credit" ||
                      selectedPaymentMethod?.name === "Delivery" ||
                      grandTotal < 0
                        ? 0.5
                        : 1,
                  }} // Apply inline style for opacity
                >
                  {t("Clear")}
                </button>
              </div>

              {/* Change  */}

              <div className="flex flex-col mt-8 text-4xl font-bold tracking-wider text-center capitalize text-zinc-800 max-md:mt-7 max-md:w-full">
                {keypadValues.map((row, rowIndex) => (
                  <div
                    key={rowIndex}
                    className="flex gap-2 mb-3 max-md:flex-wrap max-md:w-full"
                  >
                    {row.map((value, index) => (
                      <KeypadButton
                        key={index}
                        value={value.value}
                        className={value.className}
                        onClick={() => handleKeypadButtonClick(value.value)}
                        isBankPaymentSelected={isBankPaymentSelected}
                        style={value.isIcon ? { padding: "0.1rem" } : null}
                        grandTotal={grandTotal}
                        selectedPaymentMethod={selectedPaymentMethod}
                      >
                        {value.isIcon ? (
                          <img
                            src={value.icon}
                            alt="icon"
                            style={{ height: "1.5rem", width: "auto" }}
                          />
                        ) : (
                          value.value
                        )}
                      </KeypadButton>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </main>
        <footer></footer>
      </section>
    </div>
  );
}

export default Payment_Modal;
