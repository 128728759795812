import React, { useEffect, useRef, useState } from "react";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { useTranslation } from "react-i18next";
import { setCustomer } from "../../Redux/Action/getcustomerAction";
import { home_icon, office_icon, OtherTag } from "../../assets";

function InputField({
  label,
  placeholder,
  changed,
  inputType,
  error,
  ...props
}) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="mt-4 mb-2 font-medium max-md:max-w-full">{label}</div>
      <div
        className={`flex border border-solid rounded-xl overflow-hidden justify-center items-start ${
          inputType === "number" && i18n.language === "ar"
            ? "flex-row-reverse"
            : ""
        }`}
      >
        {inputType === "number" && (
          <span className="pl-3 py-3 bg-white text-black max-md:pe-3">
            {i18n.language === "ar" ? "20+" : "+20"}
          </span>
        )}
        <input
          {...props}
          type="text"
          placeholder={placeholder}
          style={{ direction: inputType === "number" && "ltr" }}
          onChange={(text) => changed(text)}
          className={`p-3 rounded-xl border-0 border-neutral-400 border-opacity-50 text-black max-md:pr-5 max-md:max-w-full w-full focus:outline-none focus:border-1px ${
            inputType === "text" ? "bg-gray-100" : "bg-white"
          } ${error ? "border-red-500" : ""}`}
        />
      </div>
    </>
  );
}

function InputAdd({ label, placeholder, changed, inputType, error, ...props }) {
  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="flex flex-col">
        <div className="mt-5 mb-2 font-medium max-md:max-w-full">{label}</div>
        <div
          className={`flex border border-solid rounded-xl overflow-hidden justify-center items-start ${
            inputType === "number" && i18n.language === "ar"
              ? "flex-row-reverse"
              : ""
          }`}
        >
          <input
            {...props}
            type="text"
            placeholder={placeholder}
            style={{ direction: inputType === "number" && "ltr" }}
            onChange={(text) => changed(text)}
            className={`p-3 rounded-xl border-0 border-neutral-400 border-opacity-50 text-black max-md:pr-5 max-md:max-w-full w-full focus:outline-none focus:border-1px ${
              inputType === "text" ? "bg-white" : "bg-white"
            } ${error ? "border-red-500" : ""}`}
          />
        </div>
      </div>
    </>
  );
}

function AddCustomer_Modal({ onClose, confirm, showSkipButton }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const [mobile, setMobile] = useState("");
  const [userName, setName] = useState("");
  const [addField1, setaddField1] = useState("");
  const [addField2, setaddField2] = useState("");
  const [addField3, setaddField3] = useState("");
  const [addField4, setaddField4] = useState("");

  const [displayName, setDisplayName] = useState("");
  const [customerExist, setCutomerExist] = useState(false);
  const [clickDisable, setClickDisable] = useState(false);
  const [partnerId, setPartnerId] = useState("");
  const { user_id } = useSelector((state) => state?.login?.user?.result);
  const inputRef = useRef(null);
  const [addressTypes, setAddressTypes] = useState([]);
  const [selectedAddressType, setSelectedAddressType] = useState(null);
  const paymentReducer = useSelector((state) => state?.paymentReducer);
  const getCustomerState = useSelector((state) => state.getcustomer);
  const { selectedCustomer } = getCustomerState;

  // useEffect(() => {
  //   // Check if the selectedCustomer exists and if the fields are empty
  //   if (selectedCustomer && selectedCustomer.mobile) {
  //     const { add_field_1, add_field_2, add_field_3, add_field_4 } = selectedCustomer;

  //     if (!add_field_1 && !add_field_2 && !add_field_3 && !add_field_4) {
  //       // Fill the mobile number in the field
  //       handleCustomerMobile(selectedCustomer.mobile)
  //       // setMobile(selectedCustomer.mobile);
  //       // setDisplayName(selectedCustomer.userName);
  //     }
  //   }
  // }, [selectedCustomer]);

  useEffect(() => {
    // Check if selectedCustomer is an object or a string
    if (typeof selectedCustomer === "string") {
      // If it's a string, treat it as a mobile number
      handleCustomerMobile(selectedCustomer);
    } else if (selectedCustomer && selectedCustomer.mobile) {
      // If it's an object, check if the fields are empty
      const { add_field_1, add_field_2, add_field_3, add_field_4 } =
        selectedCustomer;

      if (!add_field_1 && !add_field_2 && !add_field_3 && !add_field_4) {
        // Fill the mobile number in the field
        handleCustomerMobile(selectedCustomer.mobile);
      }
    }
  }, [selectedCustomer]);
  // const paymentMethod = paymentReducer ? JSON.parse(paymentReducer).selectedPaymentMethod : null;
  // const { paymentMethod } = useSelector((state) => state?.paymentReducer);

  let paymentMethod = null;

  // Check if paymentReducer is a string or an object
  if (typeof paymentReducer === "string") {
    try {
      paymentMethod = JSON.parse(paymentReducer).selectedPaymentMethod;
    } catch (error) {
      console.error("Error parsing paymentReducer:", error);
    }
  } else if (typeof paymentReducer === "object") {
    paymentMethod = paymentReducer.selectedPaymentMethod;
  }

  const [hasError, setHasError] = useState({
    mobile: false,
    displayName: false,
  });

  let len = 10;

  if (/^0/.test(mobile)) {
    len = 11;
  }

  useEffect(() => {
    const fetchAddressTypes = async () => {
      try {
        const url =
          axiosInstance.defaults.baseURL === "https://uat.myginne.com/V2"
            ? URLS.GET_ADDRESS_TYPES
            : URLS.GET_ADDRESS_TYPES_2;

        const response = await axiosInstance.post(url, {});

        if (response?.data?.result?.[0]?.status_code === 200) {
          const translatedAddressTypes = response.data.result[0].response.map(
            (address) => ({
              id: address.id,
              name: t(address.name), // Translate the address name
            })
          );
          setAddressTypes(translatedAddressTypes);
          // setAddressTypes(response.data.result[0].response || []);
        }
      } catch (err) {
        console.error(err);
      }
    };

    fetchAddressTypes();
  }, []);

  const CreateCustomer = async (values) => {
    try {
      const response = await axiosInstance.post(URLS.CREATE_CUSTOMER, {
        // mobile: values?.mobile,
        mobile: values.mobile?.replace(/^0+/, ""),
        name: values?.name,
        email: "",
        user_id: user_id,
        address_type_id: values?.address_type,
        add_field_1: values?.addField1,
        add_field_2: values?.addField2,
        add_field_3: values?.addField3,
        add_field_4: values?.addField4,
      });

      if (response?.data?.result?.status_code === 200) {
        setPartnerId(response?.data.result.partner_id);

        let customer = {
          partnerId: response?.data.result.partner_id,
          userName: values?.name,
          mobile: values?.mobile?.replace(/^0+/, ""),
          address_type_id: values?.address_type,
          add_field_1: values?.addField1,
          add_field_2: values?.addField2,
          add_field_3: values?.addField3,
          add_field_4: values?.addField4,
        };
        confirm(customer);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const UpdateCustomer = async (values) => {
    // console.log("selectedAddressType",selectedAddressType);

    try {
      const response = await axiosInstance.post(URLS.UPDATE_CUSTOMER_DATA, {
        mobile: values.mobile?.replace(/^0+/, ""),
        name: values.name,
        email: "",
        user_id: user_id,
        address_type_id: selectedAddressType?.id,
        // address_type_id: selectedAddressType?.type_id,
        add_field_1: values?.addField1,
        add_field_2: values?.addField2,
        add_field_3: values?.addField3,
        add_field_4: values?.addField4,
      });
      // console.log("update data", response);
      if (response?.data) {
        let customer = {
          partnerId: partnerId,
          userName: userName,
          mobile: mobile,
          address_type_id: selectedAddressType?.id,
          add_field_1: values?.addField1,
          add_field_2: values?.addField2,
          add_field_3: values?.addField3,
          add_field_4: values?.addField4,
        };
        confirm(customer);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // console.log("RESS ADD", selectedAddressType);

  const handleConfirm = async () => {
    // let values = { mobile, name: displayName };
    let values = {
      mobile,
      name: displayName,
      address_type: selectedAddressType?.id,
      addField1,
      addField2,
      addField3,
      addField4,
    };

    // console.log("ADD", houseNo, groupNo, street, additionalAddressInfo, company, floor, building, apartmentNo, buildingGroup, additionalInfo);

    setHasError({ mobile: false, displayName: false });

    if (!mobile) {
      setHasError({
        mobile: !mobile,
      });
      return;
    }

    if (customerExist) {
      await UpdateCustomer(values);
    } else {
      await CreateCustomer(values);
    }
  };

  const handleCustomerMobile = async (value) => {
    // Check if the value is a valid mobile number
    if (value.length >= 10) {
      // Remove leading zeros
      const cleanedValue = value.replace(/^0+/, "");

      // Check if the cleaned value is empty after removing leading zeros
      if (cleanedValue.length === 0) {
        // Handle the case where the mobile number is invalid (e.g., all zeros)
        console.warn("Invalid mobile number: all zeros");
        setCutomerExist(false);
        setDisplayName("");
        setName("");
        setPartnerId("");
        setAddressTypes([
          { id: 1, name: t("Villa") },
          { id: 2, name: t("Office") },
          { id: 3, name: t("Apartment") },
        ]);
        setSelectedAddressType(null);
        setaddField1("");
        setaddField2("");
        setaddField3("");
        setaddField4("");
        setClickDisable(false);
        return; // Exit the function early
      }

      let payloads = {
        user_id: user_id,
        mobile: cleanedValue,
      };

      const response = await axiosInstance.post(
        URLS.GET_CUSTOMER_DETAILS,
        payloads
      );

      // console.log("CUSTOMER Response", response?.data?.result);

      if (response?.data?.result?.status === "Customer does not Found") {
        setCutomerExist(false);
        setDisplayName("");
        setName("");
        setPartnerId("");
        setAddressTypes([
          { id: 1, name: t("Villa") },
          { id: 2, name: t("Office") },
          { id: 3, name: t("Apartment") },
        ]);
        setSelectedAddressType(null);
        setaddField1("");
        setaddField2("");
        setaddField3("");
        setaddField4("");
        setClickDisable(false);
      } else {
        // If customer exists
        setMobile(response?.data?.result.partners_details?.phone);
        setName(response?.data.result.partners_details.name);
        setDisplayName(response?.data.result.partners_details.name);
        setCutomerExist(true);
        setPartnerId(response?.data.result.partners_details.id);

        const addresses =
          response?.data?.result?.partners_details?.addresses || [];

        // console.log("addresses", addresses);
        let add = {
          id: response?.data?.result?.partners_details?.addresses[0]?.type_id,
          name: response?.data?.result?.partners_details?.addresses[0]?.name,
        };
        handleClick(add);

        // If addresses are not found, show default address types
        if (addresses.length === 0 || addresses[0]?.type_id === false) {
          setAddressTypes([
            { id: 1, name: t("Villa") },
            { id: 2, name: t("Office") },
            { id: 3, name: t("Apartment") },
          ]);
          setaddField1("");
          setaddField2("");
          setaddField3("");
          setaddField4("");
        } else {
          // Populate the address types if addresses exist
          setAddressTypes(
            addresses.map((address) => ({
              id: address.type_id,
              name: t(address.name),
            }))
          );
        }

        setClickDisable(true);

        // Set selected address if available
        const defaultAddressType = addresses.length > 0 ? addresses[0] : null;
        setSelectedAddressType(defaultAddressType);
        handleClick(add);

        if (defaultAddressType) {
          setaddField1(defaultAddressType.add_field_1);
          setaddField2(defaultAddressType.add_field_2);
          setaddField3(defaultAddressType.add_field_3);
          setaddField4(defaultAddressType.add_field_4);
        }
      }
    }
  };

  // const handleCustomerMobile = async (value) => {
  //   if (value.length >= 10) {
  //     let payloads = {
  //       user_id: user_id,
  //       mobile: value?.replace(/^0+/, ""),
  //     };

  //     const response = await axiosInstance.post(
  //       URLS.GET_CUSTOMER_DETAILS,
  //       payloads
  //     );

  //     // console.log("CUSTOMER Response", response?.data?.result);

  //     if (response?.data?.result?.status === "Customer does not Found") {
  //       setCutomerExist(false);
  //       setDisplayName("");
  //       setName("");
  //       setPartnerId("");
  //       setAddressTypes([
  //         { id: 1, name: t("Villa") },
  //         { id: 2, name: t("Office") },
  //         { id: 3, name: t("Apartment") },
  //       ]);
  //       setSelectedAddressType(null);
  //       setaddField1("");
  //       setaddField2("");
  //       setaddField3("");
  //       setaddField4("");
  //       setClickDisable(false);
  //     } else {
  //       // If customer exists
  //       setMobile(response?.data?.result.partners_details?.phone);
  //       setName(response?.data.result.partners_details.name);
  //       setDisplayName(response?.data.result.partners_details.name);
  //       setCutomerExist(true);
  //       setPartnerId(response?.data.result.partners_details.id);

  //       const addresses =
  //         response?.data?.result?.partners_details?.addresses || [];

  //       // console.log("addresses", addresses);
  //       let add = {
  //         id: response?.data?.result?.partners_details?.addresses[0]?.type_id,
  //         name: response?.data?.result?.partners_details?.addresses[0]?.name,
  //       };
  //       handleClick(add);

  //       // renderAddressFields()

  //       // If addresses are not found, show default address types
  //       if (addresses.length === 0 || addresses[0]?.type_id === false) {
  //         setAddressTypes([
  //           { id: 1, name: t("Villa") },
  //           { id: 2, name: t("Office") },
  //           { id: 3, name: t("Apartment") },
  //         ]);
  //         setaddField1("");
  //         setaddField2("");
  //         setaddField3("");
  //         setaddField4("");
  //       } else {
  //         // Populate the address types if addresses exist
  //         setAddressTypes(
  //           addresses.map((address) => ({
  //             id: address.type_id,
  //             name: t(address.name),
  //           }))
  //         );
  //       }

  //       setClickDisable(true);

  //       // Set selected address if available
  //       const defaultAddressType = addresses.length > 0 ? addresses[0] : null;
  //       setSelectedAddressType(defaultAddressType);
  //       handleClick(add);

  //       if (defaultAddressType) {
  //         setaddField1(defaultAddressType.add_field_1);
  //         setaddField2(defaultAddressType.add_field_2);
  //         setaddField3(defaultAddressType.add_field_3);
  //         setaddField4(defaultAddressType.add_field_4);
  //       }
  //     }
  //   }
  // };

  const isAddressFieldsEmpty = () => {
    return !addField1 && !addField2 && !addField3 && !addField4;
  };

  const handleSkip = () => {
    confirm({ skip: true });
    onClose();
  };

  const handleClick = (address) => {
    // console.log("DDDDDD", address);

    setSelectedAddressType(address);
    if (!clickDisable) {
      setaddField1("");
      setaddField2("");
      setaddField3("");
      setaddField4("");
    }
    renderAddressFields();
  };

  const handleKeyDown = (e) => {
    const normalizedKey =
      {
        // "ش": "Enter", // Arabic "ش" is similar to English "Enter"
        إ: "Shift", // Arabic "إ" is similar to English "Shift"
        Esc: "Escape", // Arabic "Esc" is similar to English "Escape"
      }[e.key] || e.key; // Fallback to the original key if no mapping exists

    if (e.key === "Enter") {
      e.preventDefault();
      // setHasError({displayName: false });
      if (!displayName) {
        setHasError({ ...hasError, displayName: true });
      } else {
        setHasError({ ...hasError, displayName: false });
        handleConfirm();
      }
    } else if (e.shiftKey && showSkipButton && normalizedKey === "Shift") {
      e.preventDefault(); // Ensure that the Shift key combination is handled
      handleSkip();
    } else if (normalizedKey === "Escape") {
      e.preventDefault();
      onClose();
    }
  };

  useEffect(() => {
    inputRef.current?.focus();
    window.addEventListener("keydown", handleKeyDown);
    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [
    displayName,
    hasError,
    showSkipButton,
    handleConfirm,
    handleSkip,
    onClose,
  ]);

  const renderAddressFields = () => {
    if (!selectedAddressType) return null;
    // console.log("selectedAddressType", selectedAddressType);

    switch (selectedAddressType?.id) {
      case 1:
        return (
          <>
            <div className="flex gap-4">
              <InputAdd
                label={t("House No. / Villa No.")}
                inputType="text"
                value={addField1}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField1(e.target.value)}
              />
              <InputAdd
                label={t("Group No.")}
                inputType="text"
                value={addField2}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField2(e.target.value)}
              />
            </div>
            <div className="flex gap-4">
              <InputAdd
                label={t("Street")}
                inputType="text"
                value={addField3}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField3(e.target.value)}
              />
              <InputAdd
                label={t("Additional Information")}
                inputType="text"
                value={addField4}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField4(e.target.value)}
              />
            </div>
          </>
        );
      case 2:
        return (
          <>
            <div className="flex gap-4">
              <InputAdd
                label={t("Company")}
                inputType="text"
                value={addField1}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField1(e.target.value)}
              />
              <InputAdd
                label={t("Floor")}
                inputType="text"
                value={addField2}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField2(e.target.value)}
              />
            </div>
            <div className="flex gap-4">
              <InputAdd
                label={t("Building")}
                inputType="text"
                value={addField3}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField3(e.target.value)}
              />
              <InputAdd
                label={t("Additional Information")}
                inputType="text"
                value={addField4}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField4(e.target.value)}
              />
            </div>
          </>
        );
      case 3:
        return (
          <>
            <div className="flex gap-4">
              <InputAdd
                label={t("Building & Group")}
                inputType="text"
                value={addField1}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField1(e.target.value)}
              />
              <InputAdd
                label={t("Apartment No.")}
                inputType="text"
                value={addField2}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField2(e.target.value)}
              />
            </div>
            <div className="flex gap-4">
              <InputAdd
                label={t("Floor")}
                inputType="text"
                value={addField3}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField3(e.target.value)}
              />
              <InputAdd
                label={t("Additional Information")}
                inputType="text"
                value={addField4}
                // readOnly={
                //   (mobile != "" && userName != "") || mobile?.length < 10
                //     ? true
                //     : false
                // }
                changed={(e) => setaddField4(e.target.value)}
              />
            </div>
          </>
        );
      default:
        return null;
    }
  };

  const getIcon = (address) => {
    // console.log("address",address);

    switch (address) {
      case 1:
        return home_icon;
      case 2:
        return office_icon;
      case 3:
        return OtherTag;
      default:
        return null;
    }
  };
  // console.log("paymentMethod", paymentMethod);

  return (
    <>
      <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50 backdrop-blur-md drop-shadow-sm">
        <div className="fixed top-0 left-0 w-full h-full bg-black opacity-50 z-40"></div>
        <section
          className="relative flex flex-col px-12 py-12 text-lg font-semibold leading-6 bg-white rounded-[25px] text-zinc-800 max-md:px-5 z-40"
          style={{ width: "700px" }}
        >
          <header className="flex gap-5 text-3xl max-md:flex-wrap max-md:max-w-full">
            <h1 className="flex-auto my-auto">{t("Add Customer")}</h1>
            <button
              type="button"
              onClick={onClose}
              className="text-zinc-800 absolute top-5 right-5"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-6 h-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </header>
          <main>
            <InputField
              ref={inputRef}
              autoFocus={true}
              type={"number"}
              inputType={"number"}
              label={t("Enter phone number")}
              value={mobile}
              changed={(e) => {
                if (e.target.value === "") {
                  setMobile("");
                  setName("");
                }
                const inputValue = e.target.value.replace(/\D/g, ""); // Remove non-digit characters
                let maxLength = 10;

                if (/^0/.test(inputValue)) {
                  maxLength = 11;
                }
                if (inputValue.length < 10) {
                  setDisplayName("");
                  setName("");
                }

                if (
                  /^\d*$/.test(inputValue) &&
                  inputValue.length <= maxLength
                ) {
                  setMobile(inputValue);
                  handleCustomerMobile(inputValue);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleConfirm();
                }
              }}
            />
            <InputField
              label={t("Enter Name")}
              inputType={"text"}
              value={displayName}
              readOnly={
                (mobile != "" && userName != "") || mobile?.length < 10
                  ? true
                  : false
              }
              changed={(e) => {
                const char = e.target.value;
                if (/^[a-zA-Z\u0600-\u06FF\s]*$/.test(char)) {
                  // Added \s to allow spaces
                  setDisplayName(char);
                }
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  handleConfirm();
                }
              }}
              maxLength={30}
            />

            {/* Display Address Types */}
            <span className="flex justify-start mt-8 ">
              {" "}
              {t("Add Address")}{" "}
            </span>
            <div className="flex justify-start gap-8 mt-2">
              {addressTypes.map((address) => (
                <button
                  key={address.id}
                  onClick={() => {
                    handleClick(address);
                    // setSelectedAddressType(address);
                    // if(!clickDisable){
                    //   setaddField1("");
                    //   setaddField2("");
                    //   setaddField3("");
                    //   setaddField4("");
                    // }
                  }}
                  className={`px-4 py-2 rounded-lg border ${
                    selectedAddressType?.id === address.id
                      ? "text-[#1B4597] bg-gray border-[#1B4597] border-solid"
                      : "bg-white border border-solid border-stone-300 text-zinc-600"
                  } flex items-center`}
                >
                  {getIcon(address.id) && (
                    <img
                      src={getIcon(address.id)}
                      alt={`${address.name} icon`}
                      className="w-6 h-6 me-2"
                    />
                  )}
                  {address.name}
                </button>
              ))}
            </div>

            {renderAddressFields()}
          </main>
          <footer className="flex justify-between gap-5 items-center">
            {showSkipButton && (
              <button
                onClick={handleSkip}
                className="justify-center items-center px-16 py-3.5 mt-12 text-xl text-center text-[#7EC242] rounded-lg border-2 border-[#7EC242] border-solid capitalize whitespace-nowrap bg-white rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
                style={{ width: "500px" }}
              >
                {t("Skip")}
              </button>
            )}
            <button
              disabled={
                (paymentMethod?.name === "Delivery" &&
                  isAddressFieldsEmpty()) ||
                mobile.length === 0 ||
                mobile.length !== len
              }
              onClick={handleConfirm}
              className="justify-center items-center px-16 py-3.5 mt-12 text-xl text-center text-white capitalize whitespace-nowrap bg-[#7EC242] rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full"
              style={{
                width: "600px",
                opacity:
                  (paymentMethod?.name === "Delivery" &&
                    isAddressFieldsEmpty()) ||
                  mobile.length === 0 ||
                  mobile.length !== len
                    ? 0.5
                    : 1,
              }}
              //   opacity: mobile.length > 0 && mobile.length === len ? 1 : 0.5,
              // }}
            >
              {t("Confirm")}
            </button>
          </footer>
        </section>
      </div>
    </>
  );
}

export default AddCustomer_Modal;
