// src/redux/actions/customerActions.js
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";

export const FETCH_CUSTOMERS_REQUEST = 'FETCH_CUSTOMERS_REQUEST';
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS';
export const FETCH_CUSTOMERS_FAILURE = 'FETCH_CUSTOMERS_FAILURE';
export const SET_CUSTOMER = 'SET_CUSTOMER';
export const CLEAR_CUSTOMER = 'CLEAR_CUSTOMER';


export const fetchCustomersRequest = () => ({
    type: FETCH_CUSTOMERS_REQUEST
});

export const fetchCustomersSuccess = (customers) => ({
    type: FETCH_CUSTOMERS_SUCCESS,
    payload: customers
});

export const fetchCustomersFailure = (error) => ({
    type: FETCH_CUSTOMERS_FAILURE,
    payload: error
});

export const setCustomer = (customer) => ({
    type: SET_CUSTOMER,
    payload: customer
});

export const clearCustomer = () => ({
    type: CLEAR_CUSTOMER
});


export const fetchCustomers = (user_id, mobile) => {
    return async (dispatch) => {
        dispatch(fetchCustomersRequest());
        try {
            const response = await axiosInstance.post(URLS.GET_CUSTOMER_DETAILS, { user_id: user_id, mobile: mobile });
            dispatch(fetchCustomersSuccess(response.data.customers));
        } catch (error) {
            dispatch(fetchCustomersFailure(error.message));
        }
    };
};