import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { add_icon, substract_icon } from "../../assets";
import { useTranslation } from "react-i18next";

export function InvoiceButton({ width, onClick }) {
  const { t, i18n } = useTranslation();
  return (
    <button
      onClick={onClick}
      className={`flex flex-col justify-center ms-10 w-5/12 py-4 items-center rounded-[5px] shadow-sm bg-[#1b4597] text-white whitespace-nowrap h-15 ${
        width == "full" ? "w-10/12" : "w-5/12"
      }`}
    >
      <div>{t("Print Receipt")}</div>
    </button>
  );
}

export function ResendButton({ onClick }) {
  const { t, i18n } = useTranslation();
  return (
    <button
      onClick={onClick}
      className="flex  justify-center ms-10 w-5/12 py-4 rounded-[5px] shadow-sm bg-[#1b4597] text-white whitespace-nowrap h-15 cursor-pointer"
    >
      {t("Resend Receipt")}
    </button>
  );
}

function ProductList({
  product,
  refundQtyForProduct,
  updateRefundItems,
  isChecked,
  selectAll,
  isProductSelect,
  selectedOrder,
  onCheckboxChange,
  selectAllClick,
}) {
  const { t, i18n } = useTranslation();
  const [quantity, setQuantity] = useState(product.available_refund_qty);
  const [isSelected, setIsSelected] = useState(false);
  const rateValue = product.rate;
  const value = product.rate * quantity;
  const [inputValue, setInputValue] = useState(product.available_refund_qty);
  const isRTL = i18n.language === "ar";
  const [error, setError] = useState(null);
  const inputRef = useRef(null);
  const [isFocused, setIsFocused] = useState(false);

  useEffect(() => {
    if (isSelected && isProductSelect && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isSelected, isProductSelect]);

  useEffect(() => {
    setQuantity(product?.available_refund_qty.toFixed(2));
    setInputValue(product?.available_refund_qty.toFixed(2));
  }, [product?.available_refund_qty]);

  useEffect(() => {
    setIsSelected(isChecked);
    if (isChecked) {
      if (product?.uom === "KG" || product?.uom === "Kg") {
        setQuantity(product?.available_refund_qty.toFixed(3));
      } else {
        setQuantity(product?.available_refund_qty);
      }
    } else {
      setQuantity(refundQtyForProduct);
    }
  }, [isChecked]);

  const decreaseQuantity = () => {
    if (quantity > 1) {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity - 1;
        updateRefundItems(product, newQuantity, isSelected);
        return newQuantity;
      });
    }
  };

  const increaseQuantity = () => {
    if (quantity < product.available_refund_qty) {
      setQuantity((prevQuantity) => {
        const newQuantity = prevQuantity + 1;
        updateRefundItems(product, newQuantity, isSelected);
        return newQuantity;
      });
    } else if (selectAll) {
      setQuantity(product.available_refund_qty);
      updateRefundItems(product, product.available_refund_qty, isSelected);
    }
  };

  useEffect(() => {
    if (inputRef.current && isProductSelect) {
      inputRef.current.focus();
    }
  }, [isProductSelect]);

  const handleProductClick = () => {
    // console.log("JJJJJJJJJJJJJ");

    if (selectedOrder.state === "Unpaid") {
      return; // Do nothing if the order is done
    }

    if (product.available_refund_qty > 0 && value >= 0) {
      if (product?.uom === "KG" || product?.uom === "Kg") {
        setQuantity(product?.available_refund_qty.toFixed(3));
        setInputValue(product?.available_refund_qty.toFixed(3));
      } else {
        setQuantity(product?.available_refund_qty);
        setInputValue(product?.available_refund_qty);
      }
      setIsSelected((prevState) => {
        const newIsSelected = !prevState;
        updateRefundItems(
          product,
          product?.available_refund_qty,
          newIsSelected
        );
        return newIsSelected;
      });
      if (inputRef.current) {
        inputRef.current.focus();
      }
    }
  };

  const handleQuantityChange = (e) => {
    let input = e.target.value;

    input = input.replace(/ز/g, ".");
    const regex = /^\d{0,4}(\.\d{0,3})?$/;
    if (!regex.test(input)) {
      setError(
        t("Invalid quantity format. Maximum 3 decimals and total length 4.")
      );
      return;
    }

    const newQuantity = parseFloat(input);

    if (Number(input) <= product.available_refund_qty) {
      // console.log("newQuantity", newQuantity, product.available_refund_qty);
      setQuantity(input);
      setInputValue(input);
      updateRefundItems(product, newQuantity, isSelected);
      setError(null);
    } else if (selectAll) {
      setQuantity(product.available_refund_qty);
      setInputValue(product.available_refund_qty);
      updateRefundItems(product, product.available_refund_qty, isSelected);
      setError(null);
    } else if (input === 0) {
      setError(t("Quantity cannot be zero."));
    } else {
      setError(t("Quantity cannot exceed the ordered quantity"));
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === " " && isProductSelect) {
        event.preventDefault();
        handleProductClick();
      } else if (event.altKey || event.getModifierState("AltGraph")) {
        event.preventDefault();
        if (selectedOrder.state === "Unpaid") {
          return;
        }
        selectAllClick();
      } else if (event.key == "=" && isProductSelect) {
        event.preventDefault();
        increaseQuantity();
      } else if (event.key == "-" && isProductSelect) {
        event.preventDefault();
        decreaseQuantity();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [isProductSelect, selectAllClick]);

  let refunded_quantity = product?.quantity - product?.available_refund_qty;

  useEffect(() => {
    if (isProductSelect) {
      const productElement = document.querySelector(
        `[data-product-id="${product.id}"]`
      );
      if (productElement) {
        if (productElement.offsetTop === 0) {
          productElement.parentNode.scrollTop = 70;
        } else {
          productElement.scrollIntoView({ behavior: "smooth" });
        }
      }
    }
  }, [isProductSelect, product.id]);

  return (
    <div
      onClick={handleProductClick}
      tabIndex={0}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      className={`flex items-center gap-5 p-3 text-base border-b border-solid border-slate-200 cursor-pointer ${
        value < 0 ? "disabled" : ""
      } ${isProductSelect ? "bg-blue-100" : ""}`}
      data-product-id={product.id}
    >
      <div className="flex items-center gap-3.5 self-stretch font-medium text-zinc-800 w-8/12">
        <img
          loading="lazy"
          src={product?.image || product?.image_url}
          alt={product?.name}
          className="shrink-0 aspect-square w-[35px]"
        />
        <div className="flex flex-col">
          <div className="my-auto font-semibold text-neutral-700 text-wrap">
            {product.name}
          </div>
          <div className="my-auto text-zinc-800 text-opacity-70">
            <div
              style={{ direction: "ltr", textAlign: isRTL ? "right" : "left" }}
              className="my-auto text-zinc-800 text-opacity-70"
            >
              {`${product?.quantity} X ${product.currency} ${rateValue?.toFixed(
                2
              )}`}
            </div>
            {product?.available_refund_qty <= 0 && (
              <div className="my-auto text-red-800">{t("Refunded")}</div>
            )}
          </div>
          {isSelected && (quantity > 0 || product.uom !== "Units") && (
            <>
              <div className="my-auto">
                {t("QTY")}: {product.quantity}
              </div>

              <div className="my-auto">
                {t("Refunded Quantity")}: {refunded_quantity?.toFixed(2)}
              </div>

              <div className="my-auto text-red-800">
                {t("To Refund")}: {(Number(quantity) || 0).toFixed(3)} ({" "}
                {product.currency} {(-value)?.toFixed(2)} )
              </div>

              {/* <div className="my-auto text-red-800">
                  {t("To Refund")}: {quantity} ( {product.currency}{" "}
                  {(-value)?.toFixed(2)} )
                </div> */}
            </>
          )}
        </div>
      </div>
      <div className="flex items-center ml-auto gap-2">
        <div className="text-center">
          EGP {(product?.quantity * rateValue).toFixed(2)}
        </div>
      </div>
      {isSelected && (quantity > 0 || product.uom !== "Units") && (
        <div className="flex items-center ms-auto gap-2">
          {product.uom === "KG" ||
          product?.uom === "Kg" ||
          product.uom === "كجم" ? (
            <div
              className="flex border border-solid bg-white rounded-lg w-[110px] justify-center items-center"
              onClick={(e) => e.stopPropagation()}
            >
              <input
                type="text"
                value={inputValue}
                ref={inputRef}
                style={{ direction: "ltr" }}
                onChange={handleQuantityChange}
                className="px-1 py-1 text-left border-0 w-full focus:outline-none"
                max={product.available_refund_qty}
                min={0}
                step="0.001"
              />
              <span className="px-1 border-l text-black">{"KG"}</span>
            </div>
          ) : (
            <>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  decreaseQuantity();
                }}
                disabled={quantity <= 1}
                className={`${
                  quantity <= 1 ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <img
                  loading="lazy"
                  src={substract_icon}
                  alt="substract_icon"
                  className="w-[40px]"
                />
              </button>
              <div className="w-8 text-center">{quantity}</div>
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  increaseQuantity();
                }}
                disabled={quantity >= product.available_refund_qty}
              >
                <img
                  loading="lazy"
                  src={add_icon}
                  alt="add_icon"
                  className={`w-[35px] ${
                    quantity >= product.available_refund_qty
                      ? "opacity-50 cursor-not-allowed"
                      : ""
                  }`}
                />
              </button>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default ProductList;
