import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import ProductCart from "./ProductCart";
import { CustomerSearchBar } from "./CustomerSearchBar";
import { useTotalAmount } from "../TotalAmountContext";
import { useItemCount } from "../ItemCountContext";
import PaymentModal from "../../Modal/Payment_Modal";
import {
  cash_in_session,
  cash_out_session,
  clear_cart_icon,
  customers_icon,
  logo,
  my_ginne_logo,
  return_icon,
} from "../../../assets";
import AddCustomer_Modal from "../../Modal/AddCustomer_Modal";
import { useSelector } from "react-redux";
import axiosInstance from "../../../AxiosInstance";
import { URLS } from "../../../API/API endpoints";
import { useNavigate } from "react-router-dom"; 
import {
  addProducts,
  clearProducts,
} from "../../../Redux/Action/productsAction";
import { useDispatch } from "react-redux";
import {
  clearCustomer,
  setCustomer,
} from "../../../Redux/Action/getcustomerAction";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import SessionFooter from "../sessioncomponents/SessionFooter";
import { clearReturnProducts } from "../../../Redux/Action/returnproductAction";

export const ProductBox = ({
  products,
  onRemoveProduct,
  onUpdateQuantity,
  userSelectedIndex,
  setUserSelectedIndex,
  isScannerActive,
  returnProducts,
  onClearCart,
  draftOrder,
}) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setItemCount } = useItemCount();
  const { setTotalAmount } = useTotalAmount();
  const [localProducts, setLocalProducts] = useState([products]);
  const [modals, setModals] = useState(false);
  // const [userSelectedIndex, setUserSelectedIndex] = useState(null);

  const [selectedCustomerS, setSelectedCustomer] = useState({});
  // const localProducts = useSelector((state) => state.products.products);
  const { selectedCustomer } = useSelector((state) => state.getcustomer);
  const [selectedIndex, setSelectedIndex] = useState(0); 
  const productRefs = useRef([]);

  useEffect(() => {
    const productArray = Object.values(products);
    const returnArray = Object.values(returnProducts || []).map((product) => ({
      ...product,
      isReturn: true,
    }));

    setLocalProducts([...productArray, ...returnArray]);
  }, [products, returnProducts]);

  useEffect(() => {
    setItemCount(localProducts?.length);

    let total = 0;
    localProducts?.forEach((product) => {
      const productTotal =
        parseFloat(product.sale_price || product.rate) * product.quantity;
      total += product.isReturn ? -productTotal : productTotal;
    });
    setTotalAmount(total);
  }, [localProducts, setItemCount, setTotalAmount]);


  useEffect(() => {
    if (products?.length === 0 && returnProducts?.length === 0) {
      setLocalProducts([]);
    }
  }, [products, returnProducts]);

  
  const handleClearCart = () => {
    dispatch(clearProducts());
    dispatch(clearReturnProducts());
    dispatch(clearCustomer());
    setItemCount(0);
    setTotalAmount(0);
    setLocalProducts([]);
    setSelectedIndex(null);
    navigate("/session");
  };

  const removeCustomer = () => {
    dispatch(clearCustomer());
  };

  const handleKeyDown = (e) => {
    const key = e.key.toLowerCase();
    const normalizedKey =
      {
        ـ: "j", 
        "،": "k", 
        "ٍ": "s", 
        آ: "n", 
        "ِ": "a", 
        "/": "l",
        "'": "m",
      }[key] || key;

    if (!modals) {
      if (PaymentModal === true) {
        setSelectedIndex(null);
        return;
      }
      if (normalizedKey === "arrowdown") {
        e.preventDefault();
        if (e.shiftKey) {
          setUserSelectedIndex(localProducts.length - 1); 
        } else {
          e.preventDefault();
          setUserSelectedIndex((prev) =>
            Math.min(prev + 1, localProducts.length - 1)
          );
        }
      } else if (normalizedKey === "arrowup") {
        e.preventDefault();
        setUserSelectedIndex((prev) => Math.max(prev - 1, 0));
      } else if (normalizedKey === "delete") {
        e.preventDefault();
        if (localProducts[userSelectedIndex]) {
          onRemoveProduct(localProducts[userSelectedIndex]);
        }
      } else if (e.shiftKey && normalizedKey === "backspace") {
        handleClearCart();
      }
    }
  };

  useLayoutEffect(() => {
    if (userSelectedIndex !== null && productRefs.current[userSelectedIndex]) {
      productRefs.current[userSelectedIndex].scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
    }
  }, [userSelectedIndex, localProducts]);

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    localProducts,
    selectedIndex,
    modals,
    userSelectedIndex,
    setUserSelectedIndex,
  ]);

  useEffect(() => {
    if (productRefs.current[userSelectedIndex]) {
      productRefs.current[userSelectedIndex].scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
      const scrollToSelectedProduct = () => {
        productRefs.current[userSelectedIndex].scrollIntoView({
          behavior: "smooth",
          block: "center", 
          inline: "nearest", 
        });
      };

      requestAnimationFrame(scrollToSelectedProduct);
    }
  }, [userSelectedIndex]);


  return (
    <div className="flex ms-4 flex-col w-[102%] h-[calc(138vh-4rem)]">
      <CustomerSearchBar removeCustomer={removeCustomer} />
      <div className="flex flex-col text-sm mt-2 mb-2 tracking-wide text-black whitespace-nowrap bg-white rounded-lg border border-solid border-stone-300 w-full h-full overflow-hidden">
        <header className="flex justify-between px-4 py-2 text-base font-semibold tracking-wide bg-[#FCC71D] text-zinc-800">
          <div>{t("Product")}</div>
          <div className="flex items-center justify-center">
            <div className="flex mx-12">{t("Qty")}</div>
            <div className="flex me-7 ">{t("Total")}</div>
            <img
              src={clear_cart_icon}
              alt="clear_cart_icon"
              onClick={handleClearCart}
              className={`h-6 w-6 cursor-pointer transition-transform transform ${
                i18n.language == "ar" ? "scale-x-[-1]" : ""
              }`}
            />
          </div>
        </header>
        <main className="flex flex-col overflow-y-auto flex-1 custom-scrollbar">
          {localProducts?.map((product, index) => {

            return (
              <ProductCart
                key={index}
                ref={(el) => (productRefs.current[index] = el)}
                product={product}
                onDelete={onRemoveProduct}
                onUpdateQuantity={onUpdateQuantity}
                isSelected={userSelectedIndex === index} 
                isScannerActive={isScannerActive}
                userSelectedIndex={userSelectedIndex}
              />
            );
          })}
        </main>
        {/* <img
          src={logo} // Replace with your watermark icon path
          alt="watermark"
          className="absolute left-32 top-80 justify-center items-center opacity-5" // Centered with reduced opacity
          style={{ width: "20%", height: "20%", zIndex: 1000 }} // Adjust size and z-index
        /> */}
      </div>
      <SessionFooter
        setModals={setModals}
        localProducts={localProducts}
        setSelectedIndex={setSelectedIndex}
      />
    </div>
  );
};
