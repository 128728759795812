

import React, { useState, useEffect } from "react";

const LoadingSpinner = () => {
    return (
      <div
        style={{ zIndex: 50 }}
        className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 backdrop-blur"
      >
        <div className="spinner">
          <span></span>
          <span></span>
          <span></span>
        </div>
      </div>
    );
  };

  export default LoadingSpinner;