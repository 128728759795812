import React, { useEffect, useState } from "react";
import { Input, DatePicker, Button } from "antd";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import { toast } from "react-toastify";
import LoadingSpinner from "../CustomWidgets/Laoding";

const { TextArea } = Input;

export function CustomerForm({ onSubmit, orderDetails }) {
  const [transactionDate, setTransactionDate] = useState(dayjs());
  const [paymentAmount, setPaymentAmount] = useState("");
  const [remark, setRemark] = useState("");
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [paymentHistory, setPaymentHistory] = useState([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = {
      pos_credit_id: orderDetails?.pos_credit_id,
      paid_amount: parseFloat(paymentAmount),
      payment_date: transactionDate.format("YYYY-MM-DD"),
      remark: remark, 
    };

    try {
      const response = await axiosInstance.post(
        URLS.POS_CREDIT_PAYMENT,
        payload
      );

      if (
        response.data?.result[0]?.status_code === 200 &&
        response.data?.result[0]?.status === "POS Credit Payment Done"
      ) {
        toast.success(t("Payment Submitted Successfully"));
        onSubmit();
      } else {
        console.error("Error submitting payment:", response.data);
      }
    } catch (error) {
      console.error("Error submitting payment:", error);
    }
  };

  useEffect(() => {
    if (orderDetails) {
      fetchPaymentHistory();
      setPaymentAmount(orderDetails?.credit_amount);
      setRemainingAmount(0);
    }
  }, [orderDetails]);

  const fetchPaymentHistory = async () => {
    if (orderDetails?.pos_credit_id) {
      setLoading(true);
      try {
        const response = await axiosInstance.post(
          URLS.POS_CREDIT_PAYMENT_HISTORY,
          {
            pos_credit_id: orderDetails.pos_credit_id,
          }
        );

        if (response.data?.result[0]?.status_code === 200) {
          setPaymentHistory(response.data?.result[0]?.credit_payment_id || []);
          setLoading(false);
        } else {
          console.error("Error fetching payment history:", response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching payment history:", error);
        setLoading(false);
      }
    }
  };

  const formatDate = (date) => {
    return dayjs(date).format("DD-MM-YYYY");
  };

  const handlePaymentChange = (e) => {
    const amount = e.target.value;

    if (/^[0-9]*\.?[0-9]{0,2}$/.test(amount) && amount.length <= 10) {
      setPaymentAmount(amount);

      const remaining = orderDetails.credit_amount - amount;
      setRemainingAmount(remaining >= 0 ? remaining : 0);
    }
  };

  const disableFutureDates = (current) => {
    return current && current.isAfter(dayjs());
  };

  const isPaymentValid =
    paymentAmount <= orderDetails?.credit_amount && paymentAmount > 0;

  const isCreditAmountZero = orderDetails?.credit_amount === 0;

  return (
    <>
      {loading && <LoadingSpinner />}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6 relative">
        <div className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-lg text-gray-600">{t("Name")}</label>
              <div className="flex gap-4">
                <Input
                  type="text"
                  value={orderDetails?.customer_name || ""}
                  readOnly
                  className="mt-1 block w-full rounded-md border font-semibold bg-gray-100 border-gray-300 px-3 py-2 text-lg"
                />
              </div>
            </div>
            <div>
              <label className="block text-lg text-gray-600">
                {t("Phone No.")}
              </label>
              <Input
                type="text"
                value={orderDetails?.mobile_no || ""}
                readOnly
                className="mt-1 block w-full rounded-md border font-semibold bg-gray-100 border-gray-300 px-3 py-2 text-lg"
              />
            </div>
          </div>

          <div>
            <label className="block text-lg text-gray-600">
              {t("Address")}
            </label>
            <Input
              type="text"
              value={orderDetails?.address || ""}
              readOnly
              className="mt-1 block w-full rounded-md border font-semibold bg-gray-100 border-gray-300 px-3 py-2 text-lg mb-10"
            />
          </div>

          <div className="">
            <h3 className="text-blue-800 font-normal mb-4 text-lg">
              {t("Credit Details")}
            </h3>
            <div className="grid grid-cols-2 gap-4">
              <div>
                <label className="block text-lg text-gray-600">
                  {t("Credit Amount")}
                </label>
                <Input
                  type="text"
                  value={`EGP ${orderDetails?.credit_amount || 0}`}
                  readOnly
                  className="mt-1 block w-full font-semibold bg-gray-100 rounded-md border border-gray-300 px-3 py-2 text-lg"
                />
              </div>
              {/* <br></br> */}

              {!isCreditAmountZero && (
                <div>
                  <label className="block text-lg text-gray-600">
                    {t("Remaining Amount")}
                  </label>
                  <Input
                    type="text"
                    value={`EGP ${remainingAmount}`}
                    readOnly
                    className="mt-1 block w-full font-semibold bg-gray-100 rounded-md border border-gray-300 px-3 py-2 text-lg"
                  />
                </div>
              )}
            </div>
          </div>

          {!isCreditAmountZero && (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div className="grid grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-lg mb-1 text-gray-600">
                    {t("Enter Payment Amount")}
                  </label>
                  <Input
                    type="text"
                    value={paymentAmount}
                    onChange={handlePaymentChange}
                    placeholder="EGP"
                    className={`mt-2 block font-semibold w-full rounded-md border px-3 py-2 text-lg ${
                      !isPaymentValid ? "border-red-600" : "border-gray-800"
                    }`}
                  />
                </div>
                <div>
                  <label className="block text-lg mb-2 text-gray-600">
                    {t("Transaction Date")}
                  </label>
                  <DatePicker
                    value={transactionDate}
                    onChange={(date) => setTransactionDate(date)}
                    format="DD-MM-YYYY"
                    disabledDate={disableFutureDates}
                    className="mt-1 block w-full rounded-md border font-semibold border-gray-800 px-3 py-2 text-xl"
                    size="large"
                  />
                </div>
              </div>

              <div>
                <label className="block text-lg text-gray-600">
                  {t("Remark")}
                </label>
                <Input
                  type="text"
                  maxLength={50}
                  value={remark}
                  onChange={(e) => setRemark(e.target.value)}
                  className="mt-1 block w-full rounded-md border font-semibold  border-gray-800 px-3 py-2 text-lg mb-10"
                />
              </div>
              <button
                disabled={!isPaymentValid}
                className={`justify-center items-center px-16 py-3.5 mt-20 text-xl text-center text-white font-semibold capitalize whitespace-nowrap bg-[#7EC242] rounded-lg max-md:px-5 max-md:mt-10 max-md:max-w-full ${
                  !isPaymentValid ? "opacity-50 cursor-not-allowed" : ""
                }`}
                onClick={handleSubmit}
              >
                {t("Confirm")}
              </button>
            </form>
          )}
        </div>
        <div className="bg-gray-50 p-4 rounded-lg">
          <h3 className="text-xl font-semibold mb-4">{t("Payment History")}</h3>
          <div className="space-y-2">
            {paymentHistory.length > 0 ? (
              <>
                <div className="grid grid-cols-3 font-medium text-gray-600 pb-2 sticky top-0 bg-gray-50">
                  <span>{t("Payment Date")}</span>
                  <span>{t("Paid")}</span>
                  <span>{t("Remark")}</span>
                </div>
                <div className="space-y-2 max-h-[500px] overflow-y-auto pe-2 scrollbar-thin">
                  {paymentHistory.map((payment, index) => (
                    <div
                      key={index}
                      className="grid grid-cols-3 text-gray-800 py-2 border-b border-gray-200 hover:bg-gray-100 transition-colors"
                    >
                      <span>{formatDate(payment?.payment_date)}</span>
                      <span>{`EGP ${payment?.paid_amount}`}</span>
                      <span>{payment?.remark}</span>

                    </div>
                  ))}
                </div>
              </>
            ) : (
              <div className="text-gray-600 py-2 text-center">
                {t("No data found")}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
