import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import { search_icon } from "../../assets";
import { fetchStoreOrders } from "../../Redux/Action/orderAction";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import axiosInstance from "../../AxiosInstance";
import { URLS } from "../../API/API endpoints";
import Table from "../Tables/TableOrder";
import LoadingSpinner from "../CustomWidgets/Laoding";
import { CustomerForm } from "./CustomerForm";

export function Modal({
  isOpen,
  onClose,
  children,
  title,
}) {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-2xl w-[80%]">
        <div className="flex justify-between items-center p-6 border-b">
          <h2 className="text-2xl font-semibold">{title}</h2>
          <button type="button" onClick={onClose}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-6 h-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="p-6">{children}</div>
      </div>
    </div>
  );
}

const debounce = (func, delay) => {
    let timeoutId;
    return (...args) => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

export function OrderSearchBar({fetchPosCreditData, searchTerm, setSearchTerm}) {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const ref = useRef(null);


  const handleSearchChange = (term) => {
    if (term.length >= 3) {
      fetchPosCreditData(term);
    }
    if (term=== "") {
        fetchPosCreditData(term); 
      }
  };

  const debouncedSearch = useMemo(() => debounce(handleSearchChange, 300), []);

  const handleInputChange = (e) => {
    const term = e.target.value;
    setSearchTerm(term);
    debouncedSearch(term);
  };

  useEffect(() => {
    ref.current.focus(); 
  }, [searchTerm]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.shiftKey && event.key === "x") {
        ref.current.focus();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [ref]);

  return (
    <div className={`relative flex ${isRtl ? "me-8" : "ms-8"} me-8 w-[100%]`}>
      {isRtl ? (
        <span className="absolute inset-y-0 right-0 flex items-center pr-3">
          <img src={search_icon} alt="customers_search_icon" />
        </span>
      ) : (
        <span className="absolute inset-y-0 left-0 flex items-center pl-3">
          <img src={search_icon} alt="customers_search_icon" />
        </span>
      )}
      <input
        type="text"
        ref={ref}
        placeholder={t("Search Customer Name / Phone No.")}
        className="border p-2 ps-10 rounded w-full focus:outline-none rounded-[6px]"
        value={searchTerm} // Controlled input
        onChange={handleInputChange} 
      />
    </div>
  );
}

function Credit_Table() {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.dir() === "rtl";
  const dispatch = useDispatch();
  const [filteredData, setFilteredData] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const { user } = useSelector((state) => state?.login);
  const [selectedOrderIndex, setSelectedOrderIndex] = useState(-1);
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoadingData, setIsLoadingData] = useState(false); // Define loading state
 

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedOrderDetails, setSelectedOrderDetails] = useState(null);

 
  const lang = i18n.language;

  const isCreditAmountZero = selectedOrderDetails?.credit_amount === 0;

  useEffect(() => {
    fetchPosCreditData();
  }, []);

  const fetchPosCreditData = async (searchTerm = "") => {
    const payload = { search: searchTerm }; // Include search term in the payload
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        URLS.GET_POS_CREDIT_LIST,
        payload
      );
  
      if (response?.data?.result[0]?.status_code === 200) {
        setFilteredData(response?.data?.result[0]?.response);
      }
    } catch (error) {
      console.error("Error fetching Pos Credit data:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleRowClick = async (order) => {
    setSelectedOrderDetails(order); // Set the selected order details here
    setIsModalOpen(true);
  };

  const columns = useMemo(() => {
    const baseColumns = [
      {
        Header: t("Customer Name"),
        accessor: "customer_name",
        Cell: ({ value }) => (
          <div className="my-auto text-ellipsis">{value}</div>
        ),
      },
      {
        Header: t("Phone No."),
        accessor: "mobile_no",
        Cell: ({ value }) => (
          <div className="my-auto text-ellipsis">{value}</div>
        ),
      },
      {
        Header: t("Address"),
        accessor: "address",
        Cell: ({ value }) => (
          <div className="my-auto text-ellipsis">{value}</div>
        ),
      },
      {
        Header: t("Credit Amount"),
        accessor: "credit_amount",
        Cell: ({ value }) => (
          <div className="flex-auto max-md:max-w-full">{value}</div>
        ),
        sortType: "alphanumeric",
      },
    ];

    return baseColumns;
  }, [user?.currency]);

  const handleSubmit = () => {
    fetchPosCreditData();
    setIsModalOpen(false);
  };

  let EKey = localStorage.getItem("eKeyPressed");

  const handleKeyDown = useCallback(
    (e) => {
      if (EKey === "true") {
        return;
      }
      if (e.shiftKey && e.key === "e") {
        setSelectedOrderIndex(null);
        return;
      }

      const normalizedKey = e.key.toLowerCase();

      if (normalizedKey === "arrowup" && !isModalOpen) {
        e.preventDefault();
        if (selectedOrderIndex > 0) {
          setSelectedOrderIndex(selectedOrderIndex - 1);
        }
      } else if (normalizedKey === "arrowdown" && !isModalOpen) {
        e.preventDefault();
        if (selectedOrderIndex < filteredData.length - 1) {
          setSelectedOrderIndex(selectedOrderIndex + 1);
        }
      } else if (
        normalizedKey === "enter" &&
        selectedOrderIndex >= 0 &&
        !isModalOpen
      ) {
        e.preventDefault();
        const selectedOrder = filteredData[selectedOrderIndex];
        handleRowClick(selectedOrder); // Open modal for selected row
      } else if (normalizedKey === "escape") {
        e.preventDefault();
        setIsModalOpen(false);
      } else if (e.shiftKey && normalizedKey === "e") {
        e.preventDefault();
        setIsModalOpen(false);
      }
    },
    [selectedOrderIndex, filteredData, isModalOpen, EKey]
  );

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  const [eKeyPressed, setEKeyPressed] = useState(
    localStorage.getItem("eKeyPressed")
  );

  useEffect(() => {
    const handleStorageChange = () => {
      setEKeyPressed(localStorage.getItem("eKeyPressed"));
    };
    window.addEventListener("storage", handleStorageChange);
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    if (eKeyPressed !== "true") {
      window.addEventListener("keydown", handleKeyDown);
    }
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown, eKeyPressed]);

  return (
    <>
      <Modal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        isCreditAmountZero={isCreditAmountZero}
        title={t("Customer Details")}
      >
        <CustomerForm
          onSubmit={handleSubmit}
          orderDetails={selectedOrderDetails}
        />
      </Modal>
      {loading ? (
        <div className="px-5">
          <LoadingSpinner />
        </div>
      ) : (
        <div className="flex">
          <div
            className="flex-grow transition-all duration-300 w-full"
          >
            <div className="bg-[#EEEEEE]">
              <div className="flex flex-row gap-4 bg-[#EEEEEE]">
                <div className={`${isRtl ? "ms-20" : "ms-12"} bg-[#EEEEEE]`}>
                  <OrderSearchBar
                    fetchPosCreditData={fetchPosCreditData}
                    searchTerm={searchTerm} 
                    setSearchTerm={setSearchTerm}
                    // ref={searchInputRef}
                  />
                </div>
              </div>
            </div>
            {filteredData && filteredData.length === 0 && (
              <div className="flex flex-col justify-center mr-20 ml-20 mt-8 text-sm bg-white text-left rounded-[15px] overflow-hidden">
                <p className="text-center py-4 text-lg font-semibold text-zinc-800">
                  {t("No Orders")}
                </p>
              </div>
            )}

            {filteredData && filteredData.length > 0 && (
              <div className="flex flex-col justify-center mx-20 mt-7 mb-4 pb-2 text-sm bg-white text-left rounded-[15px] overflow-hidden">
                {/* {console.log("filteredData", filteredData)} */}
                <Table
                  columns={columns}
                  data={filteredData}
                  pagination={pagination}
                  isLoadingData={isLoadingData}
                  setPagination={setPagination}
                  onRowClick={(val) => {
                    // navigate(`/credit?creditId=${val?.id}`);
                    handleRowClick(val);
                  }}
                  selectedRowIndex={selectedOrderIndex}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default Credit_Table;
