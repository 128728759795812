import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Uploadminimalistic } from "../../assets";
import { useTranslation } from "react-i18next";

const UploadMediaonly = ({
  onfileAdd,
  foldername,
  setBase64Images,
  multiple = false,
  setIsFileValid,
}) => {
  const { t } = useTranslation();

  const onDrop = useCallback(
    (acceptedFiles) => {
      const imageArray = [];
      acceptedFiles.slice(0, 4).forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          if (file.size > 5 * 1024 * 1024) {
            setIsFileValid(false);
          } else {
            imageArray.push(reader.result);
            if (imageArray.length === acceptedFiles.length) {
              setBase64Images((prev) => [...prev, ...imageArray]);
            }
          }
        };
      });
    },
    [setBase64Images, setIsFileValid]
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/jpeg, image/png, image/jpg",
    multiple,
    onDrop,
  });

  return (
    <div className="upload">
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <p className="flex flex-col justify-end text-center text-xl mt-10 text-body cursor-pointer">
          <span className="flex flex-row justify-center gap-2 items-center font-semibold text-[#1b4597]">
            <img
              className="ms-1"
              src={Uploadminimalistic}
              width={24}
              alt="Upload Icon"
            />
            {t("Upload Images")}
          </span>
          <span className="text-sm text-body text-[#999999]">
            (png, jpg, jpeg, max 5mb)
          </span>
        </p>
      </div>
    </div>
  );
};

export default UploadMediaonly;