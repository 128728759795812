import React, { useEffect, useState } from "react";
import { Button, Image, AutoComplete } from "antd";
// import "../../../styles.css";
import { debounce } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import BarcodeScanner from "react-barcode-reader";
import { useSelector } from "react-redux";
import axiosInstance from "../../../AxiosInstance";
import { URLS } from "../../../API/API endpoints";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  InventorySearchonboard,
  InventorySearchonboardBarcode,
} from "../../../API/API Functions/ProductRequest_API";
import { InputBox } from "../../CustomWidgets/InputBox";
import "./autocomplete.module.css";
import LoadingSpinner from "../../CustomWidgets/Laoding";
import AddProductBarcode from "../../Modal/AddProductBarcode";

const InventoryOnboard = () => {
  const [value, setValue] = useState("");
  const [requestProduct, setRequestProduct] = useState(false);
  const [productOptions, setProductOptions] = useState([]);
  const [productImage, setProductImage] = useState("");
  const [productName, setProductName] = useState("");
  const [availableQty, setAvailableQty] = useState(0);
  const [productUom, setProductUom] = useState("");
  const [prodUpdateState, setProdUpdateState] = useState("");
  const [isProductSelected, setIsProductSelected] = useState(false);
  const [addBarcode, setAddBarcode] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState(null);
  const [isAddBarcodeModalOpen, setIsAddBarcodeModalOpen] = useState(false);
  const [popup, setPopup] = useState(false);
  const [message, setMessage] = useState("");
  const [isProductFound, setIsProductFound] = useState(false);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const company_id = useSelector(
    (state) => state?.login?.user?.result?.company_id
  );

  // Form state
  const [formValues, setFormValues] = useState({
    barcode: "",
    qty: "",
    costPrice: "",
    salePrice: "",
    machineBarcode: "",
  });

  // Debounced search function
  const searchResult = debounce(async (value) => {
    let res = await InventorySearchonboard(value);
    if (res.result && Array.isArray(res.result)) {
      const productResponse = res.result[0];
      if (productResponse.status_code === 400) {
        setRequestProduct(true);
        setProductOptions([]);
      } else {
        setProductOptions(res.result);
        setRequestProduct(false);
      }
    } else {
      setProductOptions([]);
      setRequestProduct(false);
    }
  }, 500);



  // Debounced barcode search function
  const searchBarcodeResult = debounce(async (value) => {
    let res = await InventorySearchonboardBarcode(value);
    if (res.result && Array.isArray(res.result)) {
      const productResponse = res.result[0];
      if (productResponse.status_code === 400) {
        // Commenting out GS1 search functionality
        /*
        try {
          const fetchGtinResponse = await axiosInstance.post(
            "/fetch_gs1_data",
            {
              gtin: value,
            }
          );

          if (fetchGtinResponse.data && fetchGtinResponse.data.result) {
            const fetchedProduct = fetchGtinResponse.data.result;
            if (fetchedProduct.gtin) {
              navigate("/pos/ProductRequest", {
                state: { fetchedProduct },
              });
            } else {
              setRequestProduct(true);
              setProductOptions([]);
              setValue("");
              setIsProductFound(false);
              resetForm();
            }
          } else {
            setRequestProduct(true);
            setProductOptions([]);
            setValue("");
            setIsProductFound(false);
            resetForm();
          }
        } catch (error) {
          console.error("Error fetching GS1 data:", error);
          setRequestProduct(true);
        }
        */
        setRequestProduct(true);
        toast.error(t("Product not found"));
        setProductOptions([]);
        setValue("");
        setIsProductFound(false);
        resetForm();
      } else {
        setFormValues((prev) => ({
          ...prev,
          barcode: productResponse.barcode,
        }));
        setProductImage(productResponse.image);
        setValue(productResponse.name);
        setProductName(productResponse.name);
        setAvailableQty(productResponse.available_qty);
        setProductUom(productResponse.uom);
        setRequestProduct(false);

        if (productResponse?.cost_price > 0) {
          setFormValues((prev) => ({
            ...prev,
            costPrice: productResponse.cost_price,
          }));
        }

        if (productResponse?.sale_price > 0) {
          setFormValues((prev) => ({
            ...prev,
            salePrice: productResponse.sale_price,
          }));
        }

        setIsProductFound(true);
      }
    }
  }, 500);

  // Handle form input changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  // Handle barcode click
  const handleBarcodeClick = () => {
    if (productName && !formValues.barcode) {
      setAddBarcode(true);
      setIsAddBarcodeModalOpen(true); // Ensure this is set to true to open the modal
    }
  };

  // Submit product data
  const submitProductData = async () => {
      
    const { barcode, qty, machineBarcode, costPrice, salePrice } = formValues;

    // console.log("Submitting product data:", {
    //   barcode,
    //   qty,
    //   machineBarcode,
    //   costPrice,
    //   salePrice,
    // }); 

    if (salePrice < costPrice) {
      toast.error(t("Sale price cannot be less than cost price."));
      return;
    }

    if (!barcode) {
      setAddBarcode(true);
      setIsAddBarcodeModalOpen(true);
      toast.error(t("Please add barcode."))
      return;
    }

    if (!qty) {
      toast.error(t("Quantity is required."));
      return;
    }

    if (!salePrice) {
      toast.error(t("Sale price is required."));
      return;
    }

    if (!costPrice) {
      toast.error(t("Cost price is required."));
      return;
    }

  
      if(machineBarcode && machineBarcode.length < 7){
        toast.error(t("Product code should not be less than 7 digits."));
        return;
    }

    if (costPrice == 0 || salePrice == 0  ) {
      toast.error(t("Sale price & Cost price can not be 0."));
      return;
    }
    

    setLoading(true);
    const totalQuantity =
      availableQty > 0 ? Number(qty) + availableQty : Number(qty);

    const payload = {
      barcode,
      quantity: totalQuantity,
      product_name: productName,
      cost_price: costPrice ? Number(costPrice) : 0,
      sales_price: salePrice ? Number(salePrice) : 0,
      company_id,
      product_image: productImage,
      machine_barcode: machineBarcode || false,
    };

    try {
      const response = await axiosInstance.post(
        process.env.REACT_APP_API_URL_OLD + URLS.ADD_PRODUCT,
        payload
      );
      if (response?.data?.result?.status === "Product Created.") {
        setPopup(true);
        setMessage("Inventory Updated Successfully");
        toast.success(t("Inventory Updated Successfully"));
        resetForm();
        navigate("/session");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      setLoading(false);
      setPopup(true);
      setMessage("Error submitting data. Please try again.");
    }
  };

  // Reset form
  const resetForm = () => {
    setFormValues({
      barcode: "",
      qty: "",
      costPrice: "",
      salePrice: "",
      machineBarcode: "",
    });
    setValue("");
    setProductImage("");
    setProductName("");
    setAvailableQty(0);
    setProductUom("");
    setProdUpdateState("");
    setIsProductSelected(false);
  };

  const handleCancel = () => {
    navigate("/session");
  };

    useEffect(() => {
      if (value?.length < 3) {
      setProductOptions([])
      }
    }, [value]);

  // console.log("CCCCCCCC----", isAddBarcodeModalOpen);

  return (
    <>
      {loading && <LoadingSpinner />}
      <form>
        <div className="flex flex-col w-full md:w-[80%] h-[100%] mt-4 justify-center mx-auto p-4">
          <div className="h-10 bg-[#C9ECF7] rounded-t-xl flex items-center">
            <p className="ms-4 font-semibold text-xl">{t("Product Details")}</p>
          </div>

          <div className="flex flex-col md:flex-row flex-1 bg-white border rounded-b-xl">
            <div className="m-2 w-full md:w-2/4 p-4 text-xl">
              <div className=" flex flex-row">
                <AutoComplete
                  value={value}
                  //  variant="borderless"
                  options={productOptions.map((option) => ({
                    value: option.name,
                    label: option.name,
                  }))}
                  onSearch={(value) => {
                    if (!isProductSelected) {
                      setValue(value);
                      if (value.length >= 3) {
                        searchResult(value);
                      } else {
                        setRequestProduct(false);
                        setProductOptions([]);
                      }
                    }
                  }}
                  onSelect={(value) => {
                    const selectedProduct = productOptions.find(
                      (product) => product.name === value
                    );
                    if (selectedProduct?.barcode === false) {
                      setAddBarcode(true);
                      setIsAddBarcodeModalOpen(true);
                      setSelectedProductId(selectedProduct.prod_id);
                    }
                    setValue(selectedProduct?.name);
                    setProductImage(selectedProduct?.image);
                    setProductName(selectedProduct?.name);
                    setAvailableQty(selectedProduct?.available_qty);
                    setFormValues((prev) => ({
                      ...prev,
                      barcode: selectedProduct?.barcode,
                      costPrice: selectedProduct?.cost_price,
                      salePrice: selectedProduct?.sale_price,
                      machineBarcode: selectedProduct?.machine_barcode
                      // qty: selectedProduct?.available_qty,
                    }));
                    setProductUom(selectedProduct?.uom);
                    setProdUpdateState(selectedProduct?.updated);
                    setIsProductSelected(true);
                    setProductOptions([]);
                  }}
                  style={{
                    width: "100%",
                    height: 50, // Increased height of the input field
                    fontSize: 18, // Increased font size of the input field
                    // backgroundColor: "black"
                  }}
                  placeholder={t("Search Product")}
                  // dropdownStyle={{
                  //   fontSize: 28, // Increased font size of the dropdown options
                  // }}
                  dropdownStyle={{
                    fontSize: 20, // Font size
                    padding: "10px", // Padding
                    // backgroundColor: "#f0f0f0", // Background color
                  }}
                  readOnly={isProductSelected}
                  disabled={isAddBarcodeModalOpen}
                />

                {value && ( // Show the cross button only if there is a value
                  <button
                    type="button"
                    onClick={resetForm}
                    className=" ms-[-30px] text-black hover:text-gray-700 z-10"
                    style={{
                      fontSize: "20px",
                      padding: "0",
                      backgroundColor: "transparent",
                    }}
                  >
                    &times; {/* Cross icon */}
                  </button>
                )}
              </div>

              <BarcodeScanner
                onScan={(data) => {
                  // console.log("vvvvvvvv--->", isAddBarcodeModalOpen);

                  if (isAddBarcodeModalOpen === false) {
                    setValue(data);
                    searchBarcodeResult(data);
                  }
                }}
                onError={(err) => {
                  console.error(err);
                }}
                disabled={isAddBarcodeModalOpen}
                facingMode="environment"
                className="absolute inset-y-0 right-0 me-4 pointer-events-none cursor-pointer z-10"
              />

              {requestProduct && (
                <Link
                  to="/pos/ProductRequest"
                  style={{ color: "blue", textDecoration: "underline" }}
                  className="flex flex-col items-end text-l mt-2"
                >
                  {t("Request Product")}
                </Link>
              )}

              <InputBox
                type="number"
                name="barcode"
                label={t("Barcode")}
                placeholder={t("Enter Barcode")}
                onChange={handleInputChange}
                onClick={handleBarcodeClick}
                value={formValues.barcode}
                disabled={formValues.barcode?.length >= 13 || isAddBarcodeModalOpen}
                readOnly
              />
              <div className="flex flex-col md:flex-row gap-5 mt-2">
                <span className="flex flex-col w-[48%]">
                  <InputBox
                    type="text"
                    name="qty"
                    label={t("Quantity")}
                    placeholder={t("Enter Quantity")}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (
                        (productUom !== "Units" &&
                          /^\d*\.?\d{0,2}$/.test(value)) ||
                        (productUom === "Units" && /^\d{0,5}$/.test(value))
                      ) {
                        if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                          setFormValues((prev) => ({ ...prev, qty: value }));
                        }
                      }
                    }}
                    value={formValues.qty}
                    disabled={isAddBarcodeModalOpen}
                  />
                  {prodUpdateState === "Already Updated" ? (
                    availableQty === 0 ? ( // Check if availableQty is 0
                      <p className="text-l mt-1 italic text-red-500">
                        {t("Available Quantity:")} 0
                      </p>
                    ) : (
                      <p className="text-l mt-1 italic text-blue-900">
                        {/* {t("Inventory already updated for this product.")}
                          <br /> */}
                        <p className="text-l mt-0 italic text-red-500">
                          {t("Available Quantity:")} {availableQty}
                        </p>
                      </p>
                    )
                  ) : availableQty > 0 ? (
                    <p className="text-l mt-1 italic text-red">
                      {t("Available Quantity:")} {availableQty}
                    </p>
                  ) : null}
                </span>
                {productUom === "KG" && (
                  <span className="w-[48%]">
                  <InputBox
                    type="number"
                    name="machineBarcode"
                    label={t("Product Code")}
                    placeholder={t("Enter Product Code")}
                    onChange={(e) => {
                      const { value } = e.target;
                      if (/^\d{0,7}$/.test(value)) {
                        setFormValues((prev) => ({
                          ...prev,
                          machineBarcode: value,
                        }));
                      }
                    }}
                    value={formValues.machineBarcode}
                    disabled={isAddBarcodeModalOpen}
                  />
                </span>
                )}
              </div>

              <div className="flex flex-col md:flex-row gap-5 mt-2">
                <InputBox
                  type="text"
                  name="costPrice"
                  label={t("Cost Price")}
                  placeholder={t("Enter Cost Price")}
                  onChange={(e) => {
                    const { value } = e.target;
                    if (
                      value === "" ||
                      /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(value)
                    ) {
                      if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                        setFormValues((prev) => ({
                          ...prev,
                          costPrice: value,
                        }));
                      }
                    }
                  }}
                  value={formValues.costPrice}
                  disabled={isAddBarcodeModalOpen}
                />

                <InputBox
                  type="text"
                  label={t("Sale Price")}
                  name="salePrice"
                  placeholder={t("Enter Sale Price")}
                  // onChange={(e) => {
                  //   const { value } = e.target;
                  //   if (
                  //     value === "" ||
                  //     /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(value)
                  //   ) {
                  //     if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                  //       setFormValues((prev) => ({
                  //         ...prev,
                  //         salePrice: value,
                  //       }));
                  //     }
                  //   }
                  // }}
                  onChange={(event) => {
                    const { value } = event.target;

                    // Allow only valid decimal numbers and restrict to 7 digits total
                    if (
                      value === "" || // Allow empty input
                      /^(0|[1-9]\d{0,6})(\.\d{0,2})?$/.test(value) // Allow numbers with up to 6 digits before decimal and 2 after
                    ) {
                      // Prevent multiple hyphens and other invalid characters
                      if (!/[^\d.]/.test(value) && !/(--)/.test(value)) {
                        setFormValues((prev) => ({
                          ...prev,
                          salePrice: value,
                        }));
                      }
                    }
                  }}
                  value={formValues.salePrice}
                  disabled={isAddBarcodeModalOpen}
                />
              </div>

              <div className="flex items-center mt-8 gap-8">
                <button
                  type="button"
                  disabled={!productName}
                  onClick={submitProductData}
                  className="w-56 h-12 mt-2 bg-blue-900 text-white text-l font-semibold rounded-lg flex items-center justify-center gap-2"
                  style={{opacity: productName ? 1 : 0.5 }}
                >
                  {t("Save")}
                </button>

                <button
                  type="button"
                  onClick={handleCancel}
                  className="w-56 h-12 mt-2 bg-white text-blue-900 border-blue-900 border text-l font-semibold rounded-lg flex items-center justify-center gap-2"
                >
                  {t("Cancel")}
                </button>
              </div>
            </div>

            {/* <div className="m-2 w-full md:w-2/4 p-4 flex items-center justify-center border"> */}
            <div
              //  className="flex items-center justify-center w-72 h-72 border rounded-xl  relative top-7 left-20">
              className={`flex items-center justify-center w-96 h-96 border rounded-xl relative top-7 ${
                i18n.language === "ar" ? "right-32" : "left-32"
              }`}
            >
              {productImage ? (
                <Image
                  style={{ maxHeight: 280, maxWidth: 280 }}
                  src={productImage}
                  height={280}
                  width={280}
                  alt="Image"
                  className="rounded-xl"
                />
              ) : (
                <svg
                  className="float-left h-10 w-12 text-gray-300"
                  viewBox="0 0 24 24"
                  fill="currentColor"
                  aria-hidden="true"
                >
                  <path
                    fillRule="evenodd"
                    d="M1.5 6a2.25 2.25 0 012.25-2.25h16.5A2.25 2.25 0 0122.5 6v12a2.25 2.25 0 01-2.25 2.25H3.75A2.25 2.25 0 011.5 18V6zM3 16.06V18c0 .414.336.75.75.75h16.5A.75.75 0 0021 18v-1.94l-2.69-2.689a1.5 1.5 0 00-2.12 0l-.88.879.97.97a.75.75 0 11-1.06 1.06l-5.16-5.159a1.5 1.5 0 00-2.12 0L3 16.061zm10.125-7.81a1.125 1.125 0 112.25 0 1.125 1.125 0 01-2.25 0z"
                  />
                </svg>
              )}
            </div>
          </div>
        </div>
      </form>
      {/* {isAddBarcodeModalOpen && */}
      <AddProductBarcode
        isOpen={isAddBarcodeModalOpen}
        onClose={() => {
          setAddBarcode(false);
          setIsAddBarcodeModalOpen(false);
        }}
        prod_id={selectedProductId}
        onBarcodeAdded={(newBarcode) => {
          setFormValues((prev) => ({
            ...prev,
            barcode: newBarcode,
            // qty: selectedProduct?.available_qty,
          })); // Automatically fill the barcode field
          setAddBarcode(false); // Close the modal after adding the barcode
          setIsAddBarcodeModalOpen(false);
        }}
        setIsAddBarcodeModalOpen={setIsAddBarcodeModalOpen}
        // onBarcodeAdded={handleBarcodeAdded}
      />
      {/* } */}
    </>
  );
};

export default InventoryOnboard;
