import React, { useEffect, useState, forwardRef, useRef } from "react";
import {
  substract_icon,
  add_icon,
  icon_trash,
  check,
  close,
  close_icon,
  weight_icon,
} from "../../../assets";
import { useTranslation } from "react-i18next";
import QuantityModal from "../../Modal/Quantity_Modal";
import LooseQuantityModal from "../../Modal/LooseQuantity_Modal";

// const QuantityPopover = ({ initialQuantity, onConfirm, onCancel }) => {
//   const [tempQuantity, setTempQuantity] = useState(initialQuantity);
//   const inputRef = useRef(null);
//   const { t, i18n } = useTranslation();

//   useEffect(() => {
//     if (inputRef.current) {
//       inputRef.current.focus();
//     }
//   }, []);

//   const handleInputChange = (e) => {
//     const value = e.target.value;
//     if (value.length > 4) {
//       e.target.value = value.slice(0, 4);
//     }
//     if (!/^\d*$/.test(value)) {
//       e.target.value = value.replace(/[^0-9]/g, "");
//     }
//     setTempQuantity(value);
//     if (value === "") {
//       setTempQuantity(0);
//     } else if (value.match(/^\d*$/)) {
//       const newValue = parseInt(value, 10);
//       if (newValue < 10000) {
//         setTempQuantity(newValue);
//       }
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       onConfirm(tempQuantity);
//     }
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Escape") {
//       onCancel();
//     }
//   };

//   return (
//     <div
//       className="absolute top-0 left-0 z-10 bg-white border rounded-lg shadow-lg p-1 w-[122px] h-[100px]"
//       style={{
//         position: "absolute",
//         top: "30%",
//         left: "50%",
//         transform: "translate(-50%, -40%)",
//       }}
//     >
//       <div className="text-center text-sm font-semibold text-gray-800 mb-1">
//         {t("Qty")}
//       </div>
//       <input
//         ref={inputRef}
//         type="text"
//         pattern="[0-9]*"
//         inputMode="numeric"
//         style={{
//           direction: i18n.language === "ar" ? "ltr" : "ltr",
//         }}
//         onChange={handleInputChange}
//         onKeyPress={handleKeyPress}
//         onKeyDown={handleKeyDown}
//         className="border p-1 text-center w-full h-8"
//       />
//       <div className="flex flex-row justify-center gap-4 mt-1 mb-1">
//         <button onClick={onCancel}>
//           <img loading="lazy" src={close_icon} alt="" className="h-8 w-8" />
//         </button>

//         <button
//           onClick={() => onConfirm(tempQuantity)}
//           className="popover-check-button"
//         >
//           <img loading="lazy" src={check} alt="" className="h-6 w-6" />
//         </button>
//       </div>
//     </div>
//   );
// };

// const LooseQuantityPopover = ({
//   initialQuantity,
//   onConfirm,
//   onCancel,
//   product,
// }) => {
//   const [tempQuantity, setTempQuantity] = useState(initialQuantity);
//   const inputRef = useRef(null);
//   const { t, i18n } = useTranslation();

//   // const [port, setPort] = useState(null); // State to hold the serial port
//   // const [isConnected, setIsConnected] = useState(false); // Connection state

//   // useEffect(() => {
//   //   const connectToPort = async () => {
//   //     try {
//   //       const selectedPort = await navigator.serial.requestPort();
//   //       await selectedPort.open({ baudRate: 9600 });
//   //       setPort(selectedPort);
//   //       setIsConnected(true);
//   //       console.log("Connected to serial port:", selectedPort);

//   //       // Start reading data from the port
//   //       readFromPort(selectedPort);
//   //     } catch (error) {
//   //       console.error("Error connecting to the serial port:", error.message);
//   //     }
//   //   };

//   //   // Only connect if there's no existing connection
//   //   if (!isConnected) {
//   //     connectToPort();
//   //   }

//   //   // No cleanup function to close the port
//   // }, [isConnected]); // Dependency on isConnected

//   // const readFromPort = async (port) => {
//   //   try {
//   //     const textDecoder = new TextDecoderStream();
//   //     const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
//   //     const reader = textDecoder.readable.getReader();

//   //     while (isConnected) {
//   //       const { value, done } = await reader.read();
//   //       if (done) {
//   //         console.log("Stream closed");
//   //         break; // Exit the loop if the stream is closed
//   //       }

//   //       console.log("Weight data received:", value);

//   //       const weightMatch = value.match(/(\d+)\s*g/);
//   //       if (weightMatch) {
//   //         const weightInGrams = parseFloat(weightMatch[1]);
//   //         console.log("Weight in grams:", weightInGrams);
//   //         setTempQuantity(weightInGrams);
//   //         onConfirm(weightInGrams, product.prod_id);
//   //       } else {
//   //         console.error("Weight data format is incorrect");
//   //       }
//   //     }

//   //     await reader.cancel();
//   //     await readableStreamClosed.catch(() => {});
//   //   } catch (error) {
//   //     console.error("Error reading from serial port:", error.message);
//   //   }
//   // };

//   useEffect(() => {
//     if (inputRef.current) {
//       inputRef.current.focus();
//     }
//   }, []);

//   const fetchWeight = async () => {
//     // console.log("CHLAAA");
//     // onConfirm(10)

//     // setTempQuantity(10);
//     try {
//       const port = await navigator.serial.requestPort();
//       await port.open({ baudRate: 9600 });

//       const textDecoder = new TextDecoderStream();
//       const readableStreamClosed = port.readable.pipeTo(textDecoder.writable);
//       const reader = textDecoder.readable.getReader();

//       // Read the data from the serial port
//       const { value, done } = await reader.read();
//       if (done) {
//         console.log("VALUE", value);
//         reader.releaseLock();
//         return;
//       }

//       console.log("Weight data received:", value);

//       const weightMatch = value.match(/(\d+)\s*g/);
//       if (weightMatch) {
//         const weightInGrams = parseFloat(weightMatch[1]);
//         console.log("Weight in grams:", weightInGrams);
//         // setLatestWeight(weightInGrams);
//         setTempQuantity(weightInGrams);
//         // onConfirm(weightInGrams);
//         onConfirm(weightInGrams, product.prod_id);
//       } else {
//         console.error("Weight data format is incorrect");
//       }

//       // console.log("TEMP Quantity:", tempQuantity);

//       await reader.cancel();
//       await readableStreamClosed.catch(() => {});
//       await port.close();
//       // console.log("Serial port closed");
//     } catch (error) {
//       console.error("Error accessing serial port:", error.message);
//     }
//   };

//   const handleInput = (e) => {
//     const value = e.target.value;
//     // let newValue = value.replace(/[^\d.]/g, "").replace(/\.(.*)\./g, ".$1");
//     let newValue = value.replace("ز", "."); // replace ز with .
//     let decimalCount = (newValue.match(/\./g) || []).length;
//     if (decimalCount > 1) {
//       newValue = newValue.replace(/\.(.*)\./g, ".$1"); // remove additional decimal points
//     }
//     if (newValue.includes(".")) {
//       let decimalIndex = newValue.indexOf(".");
//       let integerPart = newValue.substring(0, decimalIndex);
//       let fractionalPart = newValue.substring(decimalIndex);
//       fractionalPart = fractionalPart.replace(/[^\d]/g, ""); // remove non-numeric characters
//       if (fractionalPart.length > 3) {
//         fractionalPart = fractionalPart.substring(0, 3); // limit to 3 digits after decimal
//       }
//       newValue = integerPart + "." + fractionalPart;
//     } else {
//       newValue = newValue.replace(/[^\d]/g, ""); // allow only digits
//       if (newValue.length > 4) {
//         newValue = newValue.substring(0, 4); // limit to 4 digits without decimal
//       }
//     }
//     e.target.value = newValue;

//     setTempQuantity(newValue);
//     if (newValue === "") {
//       setTempQuantity(0);
//     } else {
//       const floatValue = parseFloat(newValue);
//       if (floatValue < 10000) {
//         setTempQuantity(floatValue);
//       }
//     }
//   };

//   const handleKeyPress = (e) => {
//     if (e.key === "Enter") {
//       onConfirm(tempQuantity);
//     }
//   };

//   const handleKeyDown = (e) => {
//     if (e.key === "Escape") {
//       onCancel();
//     }
//   };

//   return (
//     <div
//       // className="absolute top-0 left-0 z-10 bg-white border rounded-lg shadow-lg p-1 w-[145px] h-[100px]"
//       className="absolute top-0 left-0 z-10 bg-white border rounded-lg shadow-lg p-1 w-[122px] h-[100px]"
//       style={{
//         position: "absolute",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -40%)",
//       }}
//     >
//       {/* <div  className="absolute top-0 left-0 z-10 bg-white border rounded-lg shadow-lg p-1 w-[122px] h-[100px]"
//     style={{
//       position: "absolute",
//       top: "50%",
//       left: "50%",
//       transform: "translate(-50%, -40%)",
//     }}
//   > */}
//       <div className="text-center text-sm font-semibold text-gray-800 mb-1">
//         {t("Qty")}
//       </div>
//       {/* <div className="flex flex-row items-center justify-start"> */}
//       <div
//         className={`flex flex-row items-center ${
//           i18n.language === "ar" ? "justify-end" : "justify-start"
//         }`}
//       >
//         <input
//           ref={inputRef}
//           type="text"
//           pattern="[0-9]*"
//           inputMode="numeric"
//           style={{
//             direction: i18n.language === "ar" ? "ltr" : "ltr",
//           }}
//           maxLength={7}
//           // value={tempQuantity}
//           // onChange={(e)=>setTempQuantity(e.target.value)}
//           onChange={handleInput}
//           onKeyPress={handleKeyPress}
//           onKeyDown={handleKeyDown}
//           // className="px-1 py-1 rounded-lg text-left  border border-1 border-neutral-400 border-opacity-50 text-black w-[80%] focus:outline-none focus:border-2px" //weight css
//           className="px-1 py-1 rounded-xl text-left border border-1 border-neutral-400 border-opacity-50 text-black max-md:pr-5 max-md:max-w-full w-full focus:outline-none focus:border-2px"
//         />

//         <span className="absolute right-2 top-1/2 -translate-y-1/2 px-1 border-l text-md text-black max-md:pe-3">
//           {t("KG")}
//         </span>

//         {/* <span className="absolute right-8 top-1/2-translate-y-1/2 px-1 border-l text-md text-black max-md:pe-3">
//           {t("KG")}
//         </span> */}
//         {/* <button
//           onClick={() => {
//             fetchWeight();
//           }}
//           className="loose-popover"
//         >
//           <img
//             loading="lazy"
//             src={weight_icon}
//             alt=""
//             className="absolute right-0 top-7 h-8 w-8 object-contain"
//           />
//         </button> */}
//       </div>
//       <div className="flex flex-row justify-center gap-4 mt-1 mb-1">
//         <button onClick={onCancel}>
//           <img loading="lazy" src={close_icon} alt="" className="h-8 w-8" />
//         </button>

//         <button
//           onClick={() => onConfirm(tempQuantity)}
//           className="popover-check-button"
//         >
//           <img loading="lazy" src={check} alt="" className="h-6 w-6" />
//         </button>
//       </div>
//     </div>
//   );
// };

const ProductCart = forwardRef(
  (
    {
      product,
      onDelete,
      onUpdateQuantity,
      isSelected,
      isScannerActive,
      userSelectedIndex,
    },
    ref
  ) => {
    const [quantity, setQuantity] = useState(product.quantity);
    const { t, i18n } = useTranslation();
    const [tempQuantity, setTempQuantity] = useState(product.quantity); // Temporary quantity for popover
    const [isPopoverVisible, setPopoverVisible] = useState(false); // Popover visibility state
    const [activePopover, setActivePopover] = useState(null); // Track the active popover by product ID
    const isArabic = i18n.language === "ar";
    const [localProducts, setLocalProducts] = useState([product]);
    const [isModalVisible, setModalVisible] = useState(false); // For controlling modal visibility

    const inputRef = useRef(null);

    useEffect(() => {
      setQuantity(product.quantity);
    }, [product.quantity]);

    const decreaseQuantity = () => {
      if (quantity > 1) {
        const newQuantity = quantity - 1;
        setQuantity(newQuantity);
        onUpdateQuantity(product.prod_id, newQuantity);
      }
    };

    const increaseQuantity = () => {
      const newQuantity = quantity + 1;
      setQuantity(newQuantity);
      onUpdateQuantity(product.prod_id, newQuantity);
    };

    const handleInputChange = (e) => {
      let value = e.target.value;
      // Ensure the input does not start with more than one zero
      if (value.startsWith("00")) {
        value = "0";
      }
      if (value.length > 4) {
        value = value.slice(0, 4);
      }
      if (value === "" || (value.match(/^\d*$/) && Number(value) < 10000)) {
        const qty = value === "" ? 0 : Number(value);
        setQuantity(qty);
        onUpdateQuantity(product.prod_id, qty);
        // setIsEdited(true);
      }
    };

    const [activePopoverProduct, setActivePopoverProduct] = useState(false);

    useEffect(() => {
      if (activePopoverProduct == true) {
        setActivePopover(null);
      }
    }, [activePopoverProduct]);

    const handleKeyDown = (e) => {
      if (isSelected & !product.isReturn & !product.weight) {
        if (e.key === "=") {
          e.preventDefault();
          increaseQuantity();
          // setQuantity(quantity + 1);
          // onUpdateQuantity(product.prod_id, quantity + 1);
        } else if (e.key === "-") {
          e.preventDefault();
          if (quantity > 1) {
            decreaseQuantity();
            // setQuantity(quantity - 1);
            // onUpdateQuantity(product.prod_id, quantity - 1);
          }
        } else if (e.altKey || e.getModifierState("AltGraph")) {
          e.preventDefault();
          handleProductClick();
          // if (
          //   activePopover !== product.prod_id &&
          //   activePopoverProduct == true
          // ) {
          //   setActivePopover(product.prod_id);
          //   setActivePopoverProduct(true);
          // } else {
          //   setActivePopover(null);
          // }
          // setActivePopover(product.prod_id);
        } else if (e.key === "Escape") {
          e.preventDefault();
          handleCancel();
          setActivePopoverProduct(false);
        }
      }
    };

    useEffect(() => {
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [isSelected, quantity, activePopoverProduct]);

    useEffect(() => {
      if (!isSelected) {
        setActivePopover(null);
      }
    }, [isSelected]);

    const handleConfirm = (newQuantity, productId) => {
      // console.log("CHALA");
      
      if (newQuantity === 0) {
        newQuantity = 1;
      }
      // setQuantity(newQuantity);
      // onUpdateQuantity(product.prod_id, newQuantity);

      setQuantity((prevQuantity) =>
        productId === product.prod_id ? newQuantity : prevQuantity
      ); // Update only the relevant product
      onUpdateQuantity(product.prod_id, newQuantity);
      // setPopoverVisible(false);
      // setActivePopover(null); // Close the popover
      setModalVisible(false);
    };

    const handleCancel = () => {
      // setPopoverVisible(false);
      // setActivePopover(null); // Close the popover
      setModalVisible(false);
    };

    useEffect(() => {
      const handleClickOutside = (event) => {
        if (
          !event.target.closest(".popover") &&
          !event.target.closest(".popover-check-button") &&
          !event.target.closest(".loose-popover")
        ) {
          setActivePopover(null);
        }
      };

      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [activePopover]);

    const handleInput = (e) => {
      const value = e.target.value;
      let newValue = value.replace(/[^\d.]/g, "").replace(/\.(.*)\./g, ".$1");
      const decimalSplit = newValue.split(".");
      if (decimalSplit.length > 1 && decimalSplit[1].length > 3) {
        newValue = decimalSplit[0] + "." + decimalSplit[1].slice(0, 3);
      }
      e.target.value = newValue;
      setTempQuantity(newValue);
      if (newValue === "") {
        setTempQuantity(0);
      } else {
        const floatValue = parseFloat(newValue);
        if (floatValue < 10000) {
          setTempQuantity(floatValue);
        }
      }
    };

    useEffect(() => {
      if (isSelected && inputRef.current) {
        inputRef.current.focus();
      }
    }, [isSelected]);
    // console.log("UOM",product.uom)

    const handleProductClick = (product) => {
      // setActivePopover(product.prod_id);
      setModalVisible(true);
    };
    return (
      <div
        ref={ref}
        className={`flex items-center p-3 text-base border border-solid justify-between border-slate-200 ${
          isSelected ? "bg-blue-100" : ""
        }`}
      >
        <div className="flex items-center gap-3.5 self-stretch font-medium text-zinc-800 w-7/12">
          <img
            loading="lazy"
            src={product?.image_url || product?.image}
            alt={product?.name}
            className="shrink-0 aspect-square object-contain w-[40px]"
          />
          <div className="flex flex-col">
            <div className="my-auto font-semibold text-neutral-700 text-wrap">
              {product?.name}
            </div>
            <div className="my-auto text-zinc-800 text-opacity-70 font-semibold">
              {"EGP "} {product?.sale_price?.toFixed(2)}
            </div>
            {/* {product.isReturn && (
              <div className="my-auto text-red-700 text-l font-semibold">
                {t("To Refund")} : -{quantity}
              </div>
            )} */}
          </div>
        </div>
        <div className="flex items-center justify-center">
          <div className="flex items-center ps-8 pe-6">
            {/* {product?.uom === "Units" ||
            product?.uom === "KG" ||
            product?.uom === "الوحدات" ? ( */}
            {product.isReturn ? (
              <div className="my-auto text-red-700 text-l font-semibold">
                {isArabic
                  ? ` ${t("To Refund")} ${quantity?.toFixed(2)}${
                      product?.uom === "KG" ||
                      product?.uom === "Kg" ||
                      product?.uom === "كجم"
                        ? "KG" || "كجم" || "Kg"
                        : ""
                    }`
                  : `${t("To Refund")} : ${quantity?.toFixed(2)} ${
                      product?.uom === "KG" ? " KG" : ""
                    }`}
              </div>
            ) : product.uom === "Kg" ||
              product.uom == "KG" ||
              product.uom == "كجم" ? (
              // Show the input field for loose items (UOM KG) even if it's not a return product
              <div className="relative">
                <div
                  className={`flex border border-solid bg-white rounded-lg w-[110px] overflow-hidden justify-center items-center ${
                    i18n.language === "ar" ? "flex-row-reverse" : ""
                  }`}
                >
                  <input
                    // ref={inputRef}
                    type={"text"}
                    onChange={handleInput}
                    value={String(quantity)}
                    // style={{ direction: "ltr" }}
                    style={{
                      direction: i18n.language === "ar" ? "rtl" : "ltr",
                    }}
                    maxLength={2}
                    // onClick={() => setActivePopover(product.prod_id)}
                    onClick={() => handleProductClick(product)}
                    disabled={!!product.weight}
                    className="px-1 py-1 rounded-xl text-left border-0 border-neutral-400 border-opacity-50 text-black max-md:pr-5 max-md:max-w-full w-full focus:outline-none focus:border-1px"
                  />
                  <span className="px-1 border-l text-md text-black max-md:pe-3">
                    {("KG")}
                  </span>
                </div>
                {isModalVisible && (
                  <LooseQuantityModal
                    initialQuantity={quantity}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                    product={product}
                  />
                )}
              </div>
            ) : (
              <>
                <button
                  onClick={decreaseQuantity}
                  disabled={quantity <= 1}
                  className={`w-[40px] ${
                    quantity <= 1 ? "opacity-50 cursor-not-allowed" : ""
                  }`}
                >
                  <img
                    loading="lazy"
                    src={substract_icon}
                    alt="substract_icon"
                  />
                </button>
                <div className="relative">
                  <input
                    // ref={inputRef}
                    type="text"
                    value={quantity}
                    onChange={handleInputChange}
                    // onClick={() => setActivePopover(product.prod_id)}
                    onClick={() => handleProductClick(product)}
                    className="w-8 text-center border-0 border-neutral-400 border-opacity-50 focus:outline-none focus:border-1px whitespace-nowrap"
                    style={{ width: "3rem" }}
                    readOnly
                  />
                  {/* {activePopover === product.prod_id && (
                    <QuantityPopover
                      initialQuantity={quantity}
                      onConfirm={handleConfirm}
                      onCancel={handleCancel}
                      className="popover"
                    />
                  )} */}
                </div>
                <button onClick={increaseQuantity}>
                  <img
                    loading="lazy"
                    src={add_icon}
                    alt="add_icon"
                    className="w-[35px] h-10"
                  />
                </button>

                {isModalVisible && (
                  <QuantityModal
                    initialQuantity={quantity}
                    onConfirm={handleConfirm}
                    onCancel={handleCancel}
                  />
                )}
              </>
            )}
            {/* // ) : null} */}
          </div>
          <div
            className={`flex justify-center items-center w-[45px] flex-shrink-0 ${
              product.isReturn ? "me-14" : "" // Add margin-end if isReturn is true
            }`}
          >
            {(product?.sale_price * quantity).toFixed(2)}
          </div>
          {!product.isReturn && (
            <div
              onClick={() => onDelete(product)}
              className="flex ms-5 cursor-pointer w-[30px]"
            >
              <img
                loading="lazy"
                src={icon_trash}
                alt="trash_icon"
                className="w-[25px]"
              />
            </div>
          )}
        </div>
        {/* )} */}
        {/* </div> */}
      </div>
    );
  }
);

export default ProductCart;
