import React from 'react';
import Credit_Table from './CreditTable';


function Credit() {
  return (
    <>
      {/* <div className="px-20 flex gap-4 flex-wrap w-[100%]" style={{ maxWidth: "100vw", margin: "0 auto" }}> */}
      <div className='bg-[#EEEEEE] mt-5'>
        <Credit_Table/>
      </div>
    </>
  )
}

export default Credit

